import Home from "../views/Home";
import Result from "../views/Result";
import CheckingVin from "../views/CheckingVin";
import VinValidator from "../components/VinValidator";
import Regulations from "../components/Regulations";
import ErrResult from "../views/ErrResult";
// import NotFound from '../views/NotFound';

export default [
  { path: "/", name: "home", component: Home },
  { path: "/vin/:vin", name: "result", component: Result },
  { path: "/checking-vin/:vin/:valid?", name: "checkingVin", component: CheckingVin },
  {
    path: "/vin-validator/:vin",
    name: "vinValidator",
    component: VinValidator,
  },
  { path: "/index.html", component: Home },
  { path: "/index.htm", component: Home },
  { path: "/index", component: Home },
  { path: "/regulations", name: "regulations", component: Regulations },
  { path: "/incorrect-vin/:vin", name: "incorrectVin", component: ErrResult },
  { path: "/:catchAll(.*)", component: Home },
];
