export const sr = {
  free_vehicle_check: "Besplatna provera vozila",
  enter_vin_number: "Unesite VIN broj",
  must_be_17_characters: "Mora imati 17 znakova",
  check_vehicle: "Provjerite vozilo",
  buying_used_car_check_vehicle_desc:
    "Kupujete polovni automobil? Provjerite vozilo u našoj bazi podataka!",
  database_have_records_desc:
    "U našoj bazi podataka imamo preko 140.000 evidencija ukradenih vozila. Bazu podataka koriste mnoge institucije, inspektori i razmjena informacionih sistema o ukradenim vozilima.",
  checking_vehicle: "Provjera vozila",
  proccessing_please_wait: "Obrada - molimo pričekajte",
  please_wait_takes_moment: "Pričekajte. Potreban je samo trenutak.",
  vehicle_not_found: "Vozilo nije pronađeno",
  vehicle_found: "Vozilo pronađeno",
  vehicle_does_not_appear_database:
    "Vozilo se ne pojavljuje u ukradenoj bazi podataka.",
  vehicle_appear_database:
    "Vozilo se pojavljuje u bazi podataka ukradenih vozila",
  check_another_vehicle: "Provjerite drugo vozilo",
  check_vehicle_history: "Provjerite historiju vozila",
  get_more_information: "Saznajte više informacija",
  incidents_history: "Istorija incidenata",
  mileage_reading: "Očitavanje kilometraže",
  vehicle_inspections: "Pregledi vozila",
  archival_photo: "Arhivska fotografija",
  and_more: "i više",
  check_now: "Provjerite sada",
  all_rights_reserved: "Sva prava zadržana.",
  entered_vin_number: "Uneseni VIN broj",
  may_be_incorrect: "Možda je netačno",
  correct: "tacno",
  check: "Provjeri",
  incorrect: "Netačno",
  check_again: "Provjerite ponovo",
  entered_vin_is_incorrect: "Nema podataka za dati VIN ili je broj nevažeći",
  reenter_your_vin: "Molimo ponovo unesite svoj vin broj",
  accept: "Prihvati",
  regulations: "Pravila",
  back: "Nazad",
  last_checked_vins: "nedavno prebačen na VIN-ovi",
  enter_plate: "registarske tablice",
  enter_country_code: "rzhavni pozitivni broj",
  value_cannot_be_empty: "podlost se ne može pobediti",
  fast_and_reliable_stolen:
    "Brza i pozdana pouzdana provera baze podataka o ukradenom automobilu",
  welcome_our_website_desc:
    "Dobrodošli na našu veb stranicu za proveru <strong>baza podataka ukradenih automobila</strong> . Ako sumnjate da je vaš automobil ukraden, ili želite da se <strong>zaštitite od kupovine ukradenog vozila</strong> , naša u mi servant can help iou.",
  using_our_advanced_search_tools_desc:
    "Koristeći naše napredne alate za pretragu, možete lako da proverite više baze podataka za <strong>ukradene automob ile</strong> u samo nekoliko klikova. Obavezno unesite VIN ili broj tabele dotičnog automobila, a mi ćemo tražiti našu <strong>opsežnu bazu podataka</strong> da vidimo da li je prijavljen kao ukraden.",
  our_service_is_fast_desc:
    "Naša je brza, pouzdana i jednostavna za korišćenje, a redovno ažuriranje naše baze podataka kako bismo osigurali da imate najsavremenije i najsavremenije informacije. Pored oga, sa svojim korisničkim korisničkim korisnicima i mažete brzo i lako da proverite da li ima ukradenih automobila i sa pogodnostima vašeg doma.",
  do_not_take_the_risk_of_buying_desc:
    "tako da ne možete da rizikujete, možete kupiti auto aka ukradenih automobila da biste zashtitili seba i sopstvenu investiciju. start traži odmah! Ovo je besplatno",
  help: "pomoć",
  check_if_vin_correct: "Proverite tačnost VIN broja",
  check_valuation: "Proverite besplatnu procenu vozila",
  decode_vin_number: "dešifrovan VIN broi",
  additional_links: "Pozitivne veze",

  dangerous_models: "Naisigurniii modeli",
  most_popular: "Najpopularniji modeli",
  most_expensive: "Naiskupi modeli",
  according_stats_desc:
    "Prema statistici, stariji modeli sa sigurnim sistemima najmanje su podložni krajevi.",
  most_frequently_desc:
    "Najčešće krađeni modeli su popularni brendovi i modeli, a to su Volksvagen Golf i Ford Focus.",
  luxury_cars_such_as_desc:
    "Luksuzni automobili kao što su Porshe, Lamborghini i Ferari su takođe najpodložniji krajevi.",
  alarm_system: "Alarmni sistemi",
  alarm_system_desc:
    "Ugradnja alarma i sirene prisustva nekoliko nivoa zaštite od kraja.",
  steering_wheel_locks: "Hrabri kultivator",
  steering_wheel_locks_desc: "Dobro za tvoj auto",
  hidding_keys: "Sakrivanje ključeva",
  hidding_keys_desc:
    "Nikada ne ostavljate svoje ključeve na jednom mestu jer mogu u iskušenje lopova.",
  parking_on_private_areas: "parking za privatnu parcelu",
  parking_on_private_areas_desc:
    "Ako više volite da parkirate na privatnom zemljištu, možete voziti bez problema.",
  how_to_prevent_theft: "Kako odrediti državu",
  secure_parking: "Osiguran parking",
  secure_parking_desc:
    "Jedan od najboljih načina da zaštitite svoj je da ga parkirate na mestu koje je nadgledano i'm enlighten. Potražite parking mesta koja imaju kameru, alarmni sistem ili osobe omogućeno da nadgledate.",
  indoor_car_parking: "Nadzemni parking",
  indoor_car_parking_desc:
    "Zaštitite svoj automobil od vremenskih prilika i kraće korišćenjem natkrivenog parkinga u centru grada",
  limited_availability: "Ograničena dostupnost",
  limited_availability_desc:
    "Izaberite parking sa praničenim pristupom i lozinkama, što otežava lopovima.",
  megaparks: "Мегапаркови",
  megaparks_desc:
    "Ako koristite mega parking, uverite se da je vaš automobil parkiran blizu ulaza i da je jasno vidljiv.",
  monitored_parking: "Паркинг под надзором",
  monitored_parking_desc:
    "Tražite parking mesta koja nadziru CCTV i uvek zaključite svoj automobil, čak i ako ga ostavite na kra tko.",
  summary: "Rezim",
  summary_desc:
    "Krađa atomobila je ozbiljan problem širim sveta. Povećanje broja vozila na putevima pogoduje sve većoj kriminalaca kriminalaca. krađu kola se vraćaju. Policijske organizacije i vlade i borbe protiv ove vrste kriminala, uključujući unapređenje procesa kr iminalata. Obrazovanje i svest među vozačima su takođe važni za spremanje situacije koje pogoduj",
};
