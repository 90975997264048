export const el = {
  free_vehicle_check: "Δωρεάν έλεγχος οχήματος",
  enter_vin_number: "Εισαγάγετε τον αριθμό VIN",
  must_be_17_characters: "Πρέπει να έχει μήκος 17 χαρακτήρες",
  check_vehicle: "Ελέγξτε το όχημα",
  buying_used_car_check_vehicle_desc:
    "Αγοράζετε μεταχειρισμένο αυτοκίνητο; Ελέγξτε το όχημα στη βάση δεδομένων μας!",
  database_have_records_desc:
    "Στη βάση δεδομένων μας έχουμε πάνω από 140.000 αρχεία κλεμμένων οχημάτων. Η βάση δεδομένων χρησιμοποιείται από πολλά ιδρύματα, επιθεωρητές και συστήματα ανταλλαγής πληροφοριών σχετικά με κλεμμένα οχήματα.",
  checking_vehicle: "Έλεγχος οχήματος",
  proccessing_please_wait: "Επεξεργασία - περιμένετε",
  please_wait_takes_moment: "Παρακαλώ περιμένετε. Χρειάζεται μόνο μια στιγμή.",
  vehicle_not_found: "Το όχημα δεν βρέθηκε",
  vehicle_found: "Το όχημα βρέθηκε",
  vehicle_does_not_appear_database:
    "Το όχημα δεν εμφανίζεται στη κλεμμένη βάση δεδομένων.",
  vehicle_appear_database:
    "Το όχημα εμφανίζεται στη βάση δεδομένων κλεμμένων οχημάτων",
  check_another_vehicle: "Ελέγξτε ένα άλλο όχημα",
  check_vehicle_history: "Ελέγξτε το ιστορικό οχήματος",
  get_more_information: "Λάβετε περισσότερες πληροφορίες",
  incidents_history: "Ιστορία περιστατικών",
  mileage_reading: "Ανάγνωση χιλιομέτρων",
  vehicle_inspections: "Έλεγχοι οχημάτων",
  archival_photo: "Αρχειακή φωτογραφία",
  and_more: "κι αλλα",
  check_now: "Ελέγξτε τώρα",
  all_rights_reserved: "Ολα τα δικαιώματα διατηρούνται.",
  entered_vin_number: "Ο καταχωρημένος αριθμός VIN",
  may_be_incorrect: "Μπορεί να είναι λάθος",
  correct: "Σωστός",
  check: "Ελεγχος",
  incorrect: "Ανακριβής",
  check_again: "Ελέγξτε και πάλι",
  entered_vin_is_incorrect:
    "Δεν υπάρχουν δεδομένα για το δεδομένο VIN ή ο αριθμός δεν είναι έγκυρος",
  reenter_your_vin: "Εισαγάγετε ξανά τον αριθμό vin σας",
  accept: "Αποδέχομαι",
  regulations: "Κανονισμοί",
  back: "Πίσω",
  last_checked_vins: "Πρόσφατα ελεγμένα VIN",
  enter_plate: "πινακίδα κυκλοφορίας οχήματος",
  enter_country_code: "Κωδικός χώρας",
  value_cannot_be_empty: "Η τιμή δεν μπορεί να είναι κενή",
  fast_and_reliable_stolen:
    "Γρήγορος και αξιόπιστος έλεγχος βάσης δεδομένων κλεμμένων αυτοκινήτων",
  welcome_our_website_desc:
    "Καλώς ήρθατε στον ιστότοπό μας για τον έλεγχο <strong>βάσεων δεδομένων κλεμμένων αυτοκινήτων</strong> . Εάν υποψιάζεστε ότι το αυτοκίνητό σας έχει κλαπεί ή θέλετε να <strong>προστατευθείτε από την αγορά ενός κλεμμένου οχήματος</strong> , η υπηρεσία μας μπορεί να σας βοηθήσει.",
  using_our_advanced_search_tools_desc:
    "Χρησιμοποιώντας τα προηγμένα εργαλεία αναζήτησης, μπορείτε εύκολα να ελέγξετε πολλές βάσεις δεδομένων για <strong>κλεμμένα αυτοκίνητα</strong> με λίγα μόνο κλικ. Απλώς εισαγάγετε τον αριθμό VIN ή τον αριθμό πινακίδας του εν λόγω αυτοκινήτου και θα ψάξουμε στην <strong>εκτενή βάση δεδομένων</strong> μας για να δούμε εάν έχει αναφερθεί ως κλεμμένο.",
  our_service_is_fast_desc:
    "Η υπηρεσία μας είναι γρήγορη, αξιόπιστη και εύκολη στη χρήση και ενημερώνουμε τις βάσεις δεδομένων μας σε τακτική βάση για να διασφαλίσουμε ότι έχετε τις πιο ακριβείς και ενημερωμένες πληροφορίες. Επιπλέον, με τη φιλική προς το χρήστη διεπαφή μας, μπορείτε γρήγορα και εύκολα να ελέγξετε για κλεμμένα αυτοκίνητα από την άνεση του σπιτιού σας.",
  do_not_take_the_risk_of_buying_desc:
    "Επομένως, μην διακινδυνεύσετε να αγοράσετε ένα κλεμμένο αυτοκίνητο ή να οδηγήσετε ένα χωρίς να το γνωρίζετε - χρησιμοποιήστε τον έλεγχο της βάσης δεδομένων κλεμμένων αυτοκινήτων για να προστατεύσετε τον εαυτό σας και την επένδυσή σας. Ξεκινήστε την αναζήτησή σας τώρα! Είναι εντελώς δωρεάν",
  help: "Βοήθεια",
  check_if_vin_correct: "Ελέγξτε την ορθότητα του αριθμού VIN",
  check_valuation: "Δείτε μια δωρεάν αποτίμηση οχήματος",
  decode_vin_number: "Αποκωδικοποιήστε τον αριθμό VIN",
  additional_links: "Πρόσθετοι σύνδεσμοι",

  dangerous_models: "Τα πιο ασφαλή μοντέλα",
  most_popular: "Τα πιο δημοφιλή μοντέλα",
  most_expensive: "Τα πιο ακριβά μοντέλα",
  according_stats_desc:
    "Σύμφωνα με στατιστικά στοιχεία, τα παλαιότερα μοντέλα με συστήματα ασφαλείας είναι τα λιγότερο επιρρεπή στην κλοπή.",
  most_frequently_desc:
    "Τα μοντέλα που κλέβονται πιο συχνά είναι δημοφιλείς μάρκες και μοντέλα, όπως το Volkswagen Golf ή το Ford Focus.",
  luxury_cars_such_as_desc:
    "Τα πολυτελή αυτοκίνητα όπως η Porsche, η Lamborghini και η Ferrari είναι επίσης τα πιο ευάλωτα στην κλοπή.",
  alarm_system: "Συστήματα Συναγερμού",
  alarm_system_desc:
    "Τοποθέτηση συναγερμού και σειρήνας παρουσίας πολλών επιπέδων αντικλεπτικής προστασίας.",
  steering_wheel_locks: "Κλειδαριές τιμονιού",
  steering_wheel_locks_desc:
    "Ένας καλός τρόπος για να προστατεύσετε το αυτοκίνητό σας από κλοπή είναι να χρησιμοποιήσετε κλειδαριές στο τιμόνι",
  hidding_keys: "Απόκρυψη των κλειδιών",
  hidding_keys_desc:
    "Μην αφήνετε ποτέ τα κλειδιά σας σε κοινή θέα καθώς μπορεί να δελεάσουν έναν κλέφτη.",
  parking_on_private_areas: "Χώρος στάθμευσης σε ιδιωτικό χώρο",
  parking_on_private_areas_desc:
    "Προτιμώντας θέσεις στάθμευσης σε ιδιωτική γη, μπορείτε να είστε σίγουροι ότι το όχημά σας είναι ασφαλές.",
  how_to_prevent_theft: "Πώς να αποτρέψετε την κλοπή",
  secure_parking: "Ασφαλής χώρος στάθμευσης",
  secure_parking_desc:
    "Ένας από τους καλύτερους τρόπους για να προστατεύσετε το αυτοκίνητό σας από κλοπή είναι να το παρκάρετε σε χώρο που παρακολουθείται και φωτίζεται τη νύχτα. Αναζητήστε χώρους στάθμευσης που έχουν κάμερες, συστήματα συναγερμού ή προσωπικό τοποθετημένο για παρακολούθηση.",
  indoor_car_parking: "Στεγασμένοι χώροι στάθμευσης",
  indoor_car_parking_desc:
    "Προστατέψτε το αυτοκίνητό σας από τις καιρικές συνθήκες και την κλοπή χρησιμοποιώντας το στεγασμένο πάρκινγκ στο κέντρο της πόλης.",
  limited_availability: "Περιορισμένη διαθεσιμότητα",
  limited_availability_desc:
    "Επιλέξτε ένα χώρο στάθμευσης με περιορισμένη πρόσβαση και κωδικούς πρόσβασης, δυσκολεύοντας τους κλέφτες.",
  megaparks: "Μεγαπάρκα",
  megaparks_desc:
    "Εάν χρησιμοποιείτε μέγα χώρο στάθμευσης, βεβαιωθείτε ότι το αυτοκίνητό σας είναι σταθμευμένο κοντά στην είσοδο και είναι ευδιάκριτο.",
  monitored_parking: "Ελεγχόμενος χώρος στάθμευσης",
  monitored_parking_desc:
    "Αναζητήστε θέσεις στάθμευσης που παρακολουθούνται από κλειστό κύκλωμα τηλεόρασης και κλειδώνετε πάντα το αυτοκίνητό σας, ακόμα κι αν το αφήνετε για λίγο.",
  summary: "Περίληψη",
  summary_desc:
    "Η κλοπή αυτοκινήτου είναι ένα σοβαρό πρόβλημα παγκοσμίως. Η αύξηση του αριθμού των οχημάτων στους δρόμους ευνοεί την αυξανόμενη δραστηριότητα των εγκληματιών. Τα κλεμμένα αυτοκίνητα πωλούνται συχνά για ανταλλακτικά ή εξάγονται στο εξωτερικό, γεγονός που καθιστά δύσκολο για τους ιδιοκτήτες τους να τα ανακτήσουν. Αστυνομικές οργανώσεις και κυβερνητικά ιδρύματα αναλαμβάνουν πολυάριθμες ενέργειες για την πρόληψη και την καταπολέμηση αυτού του τύπου εγκλήματος, συμπεριλαμβανομένης της βελτίωσης της τεχνολογίας ασφάλειας και της διεθνούς συνεργασίας για τη δίωξη εγκληματιών. Η εκπαίδευση και η ευαισθητοποίηση των οδηγών είναι επίσης σημαντικές για την πρόληψη καταστάσεων που ευνοούν την κλοπή αυτοκινήτου",
};
