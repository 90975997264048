import { useReCaptcha } from "vue-recaptcha-v3";
import { ref } from "vue";
import axios from "axios";

export default function sendReq() {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
  const tokenV3 = ref("TOKENV3");
  const response = ref({});
  const version = ref('V2');

  const reCaptcha = async () => {
    try {
      await recaptchaLoaded();
      tokenV3.value = await executeRecaptcha("checkVin");
    } catch (error) {
      console.error(error);
    }
  };

  const sendForm = async (vin, vinValidator = true, token = 'TOKEN', reg_number, reg_country) => {
    try {
      if (token == 'TOKEN') {
        await reCaptcha();
        token = tokenV3.value;
        version.value = 'V3';
      }

      response.value = await axios.post("/api/startUp", {
        vin: vin,
        token: token,
        vinValidator,
        version: version.value,
        reg_number,
        reg_country,
      });

      return true;
    } catch (error) {
      if (error.message == "REQUEST_CANCELED") {
        return error.message;
      }
      console.error(error);
    }
    return false;
  };

  return {
    sendForm,
    response,
  };
}
