<template>
    <div :class="`${color}-border`" class="square p-4 h-100">
        <h4 :class="`${color}-text`" class="title cursor-pointer">
            {{ title }}
        </h4>
        <p class="pointer">
            <slot></slot>
        </p>
    </div>
</template>

<script>
export default {
    name: "SquareInfo",
    props: {
        color: String,
        title: String,
    },
    setup() {
    }
}
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
    cursor: default;
}

.square {
    border: 2.5px solid;
    word-wrap: break-word;
    border-radius: 15px;
    transition: .5s;
}

.square:hover {
    transform: scale(.97);
}

.red-text {
    color: #cf3f5c
}

.blue-text {
    color: #0a8bc2;
}

.yellow-text {
    color: #e9ad2d
}


.orange-text {
    color: #d4735c
}


.red-border {
    border-color: #cf3f5c;
}

.blue-border {
    border-color: #0a8bc2;
}

.yellow-border {
    border-color: #e9ad2d;
}

.orange-border {
    border-color: #d4735c;
}

.zoom-in-out {
    animation: zoom-box 1s ease;
}
</style>