<template>
    <div class="row">
        <div class="col-12">
            <h5>{{ title }}</h5>
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "StolenInfo",
    props: {
        title: String,
    }
}
</script>

<style scoped></style>