<template>
  <div
    class="
      position-relative
      overflow-hidden
      p-3 p-md-5
      m-md-3
      text-center
      bg-grey
      rounded-3
      mb-4
    "
  >
    <div class="row">
      <div class="col-12 mb-3">
        <img
          src="../assets/img/wrong-vin-icon.png"
          width="128"
          height="128"
          alt="Vehicle found"
        />
      </div>
      <div class="col-12 mb-3">
        <h2>{{ t("entered_vin_is_incorrect") }}</h2>
        <h3>{{ t("reenter_your_vin") }}</h3>
      </div>
      <div class="col-12">
        <Button @check="checkAgain" class="btn">
          {{ t("check_again").toUpperCase() }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Button from "./Button";
import { useRouter } from "vue-router";
import { onUnmounted } from 'vue';

export default {
  name: "IncorrectVin",
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();

    const checkAgain = () => {
      router.push({
        name: "home",
      });
    };

    onUnmounted(() => {
      return store.dispatch("RESET_STATE");
    });

    return {
      checkAgain,
      t,
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: white;
}
</style>