<template>
  <div v-if="statusReq != 500" class="card m-0 m-md-3 d-flex">
    <div class="card w-100">
      <div class="card-header fw-bold text-center">
        {{ t("last_checked_vins") }}
      </div>
      <div v-if="!isProgress" class="card-body w-100">
        <ul class="list-inline d-flex m-0 p-0 flex-wrap justify-content-center justify-content-md-between">
          <li
            v-for="(check, index) in checked"
            :key="index"
            class="text-center m-0 p-0 d-block mb-1"
          >
            <router-link
              :to="{ name: 'checkingVin', params: { vin: check, valid: false } }"
              class="d-inline-block text-decoration-none text-center"
              >{{ check }}</router-link
            >
          </li>
        </ul>
      </div>

      <div v-else class="card-body text-center">
        <div class="spinner-border m-5" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "LastCheckedVins",
  setup() {
    const response = ref({});
    const statusReq = ref(0);
    const checked = ref([]);
    const isProgress = ref(true);
    const store = useStore();
    const { t } = useI18n();

    onMounted(async () => {
      const checkedVins = store.getters.lastCheckedVins;

      if (checkedVins.length == 0) {
        try {
          response.value = await axios.get(
            "/api/getLastChecked"
          );

          const {
            data: { result: lastChecked },
          } = response.value;
          const { status } = response.value;

          checked.value = lastChecked.lastChecked;
          statusReq.value = status;

          store.dispatch("addLastCheckedVins", checked.value);
          isProgress.value = false;

          return true;
        } catch (error) {
          console.error(error);
          statusReq.value = 500;
        }
      } else {
        checked.value = checkedVins;
        isProgress.value = false;
      }

      return false;
    });

    return {
      checked,
      statusReq,
      isProgress,
      t,
    };
  },
};
</script>

<style scoped>
a {
  font-size: 13px;
  width: 160px;
}

a:hover {
  text-decoration: underline;
}
</style>