<template>
    <div>
        <div class="row m-md-3 d-flex justify-content-center rounded-3 mb-4">
            <div class="col-xl-4 col-12 mt-2 mt-xl-0">
                <SquareInfo color="yellow" :title="t('dangerous_models')">{{ t('according_stats_desc') }}</SquareInfo>
            </div>

            <div class="col-xl-4 col-12 mt-2 mt-xl-0">
                <SquareInfo color="red" :title="t('most_popular')">{{ t('most_frequently_desc') }}</SquareInfo>
            </div>

            <div class="col-xl-4 col-12 mt-2 mt-xl-0">
                <SquareInfo color="orange" :title="t('most_expensive')">{{ t('luxury_cars_such_as_desc') }}</SquareInfo>
            </div>

        </div>

        <div class="row m-md-3 d-flex justify-content-center rounded-3 mb-4">
            <div class="col-12 mt-5 mb-4 text-center">
                <h2 class="text-center">{{ t('how_to_prevent_theft') }}?</h2>
                <img src="../../assets/img/thiefv2.webp" class="img-fluid" width="200" height="200" alt="thief">
            </div>

            <div class="col-12 col-md-6 col-xl-3 text-center text-md-start">
                <StolenInfo :title="t('alarm_system')">{{ t('alarm_system_desc') }}</StolenInfo>
            </div>
            <div class="col-12 col-md-6 col-xl-3 text-center text-md-start">
                <StolenInfo :title="t('steering_wheel_locks')">{{ t('steering_wheel_locks_desc') }}</StolenInfo>
            </div>
            <div class="col-12 col-md-6 col-xl-3 text-center text-md-start">
                <StolenInfo :title="t('hidding_keys')">{{ t('hidding_keys_desc') }}</StolenInfo>
            </div>
            <div class="col-12 col-md-6 col-xl-3 text-center text-md-start">
                <StolenInfo :title="t('parking_on_private_areas')">{{ t('parking_on_private_areas_desc') }}</StolenInfo>
            </div>
        </div>

        <div class="row m-md-3 d-flex justify-content-center rounded-3 mb-4">
            <div class="col-12 mt-5 mb-4 text-center">
                <h2 class="text-center">{{ t('secure_parking') }}</h2>
                <p class="mt-3">{{ t('secure_parking_desc') }}</p>
            </div>

            <div class="col-xl-6 col-12 mb-4 mt-2 mt-xl-0">
                <SquareInfo color="yellow" :title="t('indoor_car_parking')">{{ t('indoor_car_parking_desc') }}</SquareInfo>
            </div>

            <div class="col-xl-6 col-12 mb-4 mt-2 mt-xl-0">
                <SquareInfo color="red" :title="t('limited_availability')">{{ t('limited_availability_desc') }}</SquareInfo>
            </div>

            <div class="col-xl-6 col-12 mt-2 mt-xl-0">
                <SquareInfo color="orange" :title="t('megaparks')">{{ t('megaparks_desc') }}</SquareInfo>
            </div>

            <div class="col-xl-6 col-12 mt-2 mt-xl-0">
                <SquareInfo color="blue" :title="t('monitored_parking')">{{ t('monitored_parking_desc') }}</SquareInfo>
            </div>
        </div>

        <div class="row m-md-3 d-flex justify-content-center rounded-3">
            <div class="col-12 mt-5 mb-4 text-center">
                <h2 class="text-center">{{ t('summary') }}</h2>
                <img src="../../assets/img/thief.webp" class="img-fluid" width="200" height="200" alt="thief">
                <p class="mt-3">{{ t('summary_desc') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import SquareInfo from './SquareInfo.vue'
import StolenInfo from './StolenInfo.vue';

export default {
    name: "DescriptionSection",
    components: { SquareInfo, StolenInfo },
    setup() {
        const { t } = useI18n();

        return {
            t,
        }
    }
}
</script>

<style scoped></style>