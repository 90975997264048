export const bg = {
  free_vehicle_check: "Безплатна проверка на автомобила",
  enter_vin_number: "Въведете VIN номер",
  must_be_17_characters: "Трябва да съдържа 17 знака",
  check_vehicle: "Проверете превозното средство",
  buying_used_car_check_vehicle_desc:
    "Купувате ли употребявана кола? Проверете превозното средство в нашата база данни!",
  database_have_records_desc:
    "В нашата база данни имаме над 140 000 записа на откраднати превозни средства. Базата данни се използва от много институции, инспектори и обмен на информационни системи за откраднати превозни средства.",
  checking_vehicle: "Проверка на превозното средство",
  proccessing_please_wait: "Обработка - моля, изчакайте",
  please_wait_takes_moment: "Моля Изчакай. Отнема само миг.",
  vehicle_not_found: "Превозното средство не е намерено",
  vehicle_found: "Намерено е превозно средство",
  vehicle_does_not_appear_database:
    "Автомобилът не се появява в базата данни за откраднати.",
  vehicle_appear_database:
    "Автомобилът се появява в базата данни за откраднати превозни средства",
  check_another_vehicle: "Проверете друго превозно средство",
  check_vehicle_history: "Проверете историята на превозното средство",
  get_more_information: "Получете повече информация",
  incidents_history: "История на инциденти",
  mileage_reading: "Отчитане на километража",
  vehicle_inspections: "Проверки на превозни средства",
  archival_photo: "Архивна снимка",
  and_more: "и още",
  check_now: "Провери сега",
  all_rights_reserved: "Всички права запазени.",
  entered_vin_number: "Въведеният VIN номер",
  may_be_incorrect: "Може да е неправилно",
  correct: "вярно",
  check: "Проверете",
  incorrect: "Неправилно",
  check_again: "Провери отново",
  entered_vin_is_incorrect: "Въведеният vin номер е неправилен",
  reenter_your_vin: "Моля, въведете отново вашия vin номер",
  accept: "Приемам",
  regulations: "Регламенти",
  back: "обратно",
  last_checked_vins: "Наскоро проверени VIN",
  enter_plate: "Регистрационна табела на МПС",
  enter_country_code: "Код на държавата",
  value_cannot_be_empty: "Стойността не може да бъде празна",
  fast_and_reliable_stolen:
    "Бърза и надеждна проверка в база данни за откраднати автомобили",
  welcome_our_website_desc:
    "Добре дошли в нашия уебсайт за проверка на <strong>бази данни за откраднати автомобили</strong> . Ако подозирате, че вашият автомобил е откраднат или искате да <strong>се предпазите от закупуване на краден автомобил</strong> , нашият сервиз може да ви помогне.",
  using_our_advanced_search_tools_desc:
    "Използвайки нашите разширени инструменти за търсене, можете лесно да проверите множество бази данни за <strong>откраднати коли</strong> само с няколко кликвания. Просто въведете VIN или регистрационния номер на въпросната кола и ние ще потърсим в нашата <strong>обширна база данни</strong> , за да видим дали е била докладвана като открадната.",
  our_service_is_fast_desc:
    "Нашата услуга е бърза, надеждна и лесна за използване и ние редовно актуализираме нашите бази данни, за да гарантираме, че разполагате с най-точната и актуална информация. Плюс това, с нашия удобен за потребителя интерфейс можете бързо и лесно да проверявате за откраднати коли от комфорта на собствения си дом.",
  do_not_take_the_risk_of_buying_desc:
    "Така че не поемайте риска да си купите крадена кола или да шофирате такава, без да знаете – използвайте нашата проверка на базата данни за откраднати коли, за да защитите себе си и своята инвестиция. Започнете търсенето си сега! Това е напълно безплатно",
  help: "Помогне",
  check_if_vin_correct: "Проверете правилността на VIN номера",
  check_valuation: "Вижте безплатна оценка на автомобила",
  decode_vin_number: "Декодирайте VIN номера",
  additional_links: "Допълнителни връзки",

  dangerous_models: "Най-безопасните модели",
  most_popular: "Най-популярните модели",
  most_expensive: "Най-скъпите модели",
  according_stats_desc:
    "Според статистиката по-старите модели със системи за сигурност са най-малко податливи на кражба.",
  most_frequently_desc:
    "Най-често крадени модели са популярни марки и модели като Volkswagen Golf или Ford Focus.",
  luxury_cars_such_as_desc:
    "Луксозните автомобили като Porsche, Lamborghini и Ferrari също са най-податливи на кражби.",
  alarm_system: "Алармени системи",
  alarm_system_desc:
    "Монтаж на аларма и сирена за наличие на няколко нива на защита против кражба.",
  steering_wheel_locks: "Кормилни ключалки",
  steering_wheel_locks_desc:
    "Добър начин да защитите колата си от кражба е да използвате ключалки на волана",
  hidding_keys: "Скриване на ключовете",
  hidding_keys_desc:
    "Никога не оставяйте ключовете си на видно място, тъй като могат да изкушат крадеца.",
  parking_on_private_areas: "Паркинг на частни терени",
  parking_on_private_areas_desc:
    "Предпочитайки места за паркиране на частна земя, можете да сте сигурни, че автомобилът ви е в безопасност.",
  how_to_prevent_theft: "Как да предотвратим кражба",
  secure_parking: "Охраняем паркинг",
  secure_parking_desc:
    "Един от най-добрите начини да защитите колата си от кражба е да я паркирате на място, което се наблюдава и осветява през нощта. Потърсете места за паркиране, които имат камери, алармени системи или персонал, който да наблюдава.",
  indoor_car_parking: "Покрити паркинги",
  indoor_car_parking_desc:
    "Защитете колата си от времето и кражби, като използвате покрития паркинг в центъра на града.",
  limited_availability: "Ограничена наличност",
  limited_availability_desc:
    "Изберете паркинг с ограничен достъп и пароли, затрудняващи крадците.",
  megaparks: "Мегапаркове",
  megaparks_desc:
    "Ако използвате мега паркинг, уверете се, че колата ви е паркирана близо до входа и се вижда ясно.",
  monitored_parking: "Наблюдаван паркинг",
  monitored_parking_desc:
    "Търсете места за паркиране, които се наблюдават от видеонаблюдение и винаги заключвайте колата си, дори ако я оставите за кратко.",
  summary: "Резюме",
  summary_desc:
    "Кражбата на коли е сериозен проблем в световен мащаб. Увеличаването на броя на превозните средства по пътищата благоприятства нарастващата активност на престъпниците. Откраднатите автомобили често се продават за части или се изнасят в чужбина, което затруднява собствениците им да ги върнат. Полицейските организации и държавните институции предприемат многобройни действия за предотвратяване и борба с този вид престъпност, включително подобряване на технологиите за сигурност и международно сътрудничество при преследване на престъпници. Образованието и осведомеността сред водачите също са важни за предотвратяване на ситуации, които благоприятстват кражбата на автомобил",
};
