<template>
    <ResultStolen />
</template>

<script>
import ResultStolen from "../components/ResultStolen";

export default {
  name: "Result",
  components: {
    ResultStolen,
  },
};
</script>

<style>
</style>