export const da = {
  free_vehicle_check: "Gratis biltjek",
  enter_vin_number: "Indtast VIN-nummer",
  must_be_17_characters: "Skal være på 17 tegn",
  check_vehicle: "Tjek køretøjet",
  buying_used_car_check_vehicle_desc:
    "Køber du en brugt bil? Tjek køretøj i vores database!",
  database_have_records_desc:
    "I vores database har vi over 140.000 registreringer af stjålne køretøjer. Databasen bruges af mange institutioner, inspektører og udveksling af informationssystemer om stjålne køretøjer.",
  checking_vehicle: "Kontrol af køretøj",
  proccessing_please_wait: "Behandler - vent venligst",
  please_wait_takes_moment: "Vent venligst. Det tager kun et øjeblik.",
  vehicle_not_found: "Køretøj ikke fundet",
  vehicle_found: "Køretøj fundet",
  vehicle_does_not_appear_database:
    "Køretøjet optræder ikke i den stjålne database.",
  vehicle_appear_database: "Køretøjet vises i databasen for stjålne køretøjer",
  check_another_vehicle: "Tjek et andet køretøj",
  check_vehicle_history: "Tjek køretøjets historie",
  get_more_information: "Få flere oplysninger",
  incidents_history: "Hændelser historie",
  mileage_reading: "Kilometeraflæsning",
  vehicle_inspections: "Bilsyn",
  archival_photo: "Arkivfoto",
  and_more: "og mere",
  check_now: "Tjek nu",
  all_rights_reserved: "Alle rettigheder forbeholdes.",
  entered_vin_number: "Det indtastede VIN-nummer",
  may_be_incorrect: "Det kan være forkert",
  correct: "Korrekt",
  check: "Kontrollere",
  incorrect: "Ukorrekt",
  check_again: "Tjek igen",
  entered_vin_is_incorrect:
    "Der er ingen data for det givne VIN eller nummeret er ugyldigt",
  reenter_your_vin: "Indtast venligst dit vin-nummer igen",
  accept: "Acceptere",
  regulations: "Forskrifter",
  back: "Tilbage",
  last_checked_vins: "For nylig kontrollerede VIN'er",
  enter_plate: "Køretøjets nummerplade",
  enter_country_code: "Landekode",
  value_cannot_be_empty: "Værdien må ikke være tom",
  fast_and_reliable_stolen:
    "Hurtig og pålidelig tjek af databasen over stjålne biler",
  welcome_our_website_desc:
    "Velkommen til vores hjemmeside for kontrol af <strong>stjålne bildatabaser</strong> . Hvis du har mistanke om, at din bil er blevet stjålet, eller du ønsker at <strong>beskytte dig selv mod at købe et stjålet køretøj</strong> , kan vores service hjælpe dig.",
  using_our_advanced_search_tools_desc:
    "Ved hjælp af vores avancerede søgeværktøjer kan du nemt tjekke flere databaser for <strong>stjålne biler</strong> med blot et par klik. Indtast blot VIN- eller pladenummeret på den pågældende bil, så søger vi i vores <strong>omfattende database</strong> for at se, om den er blevet meldt stjålet.",
  our_service_is_fast_desc:
    "Vores service er hurtig, pålidelig og nem at bruge, og vi opdaterer vores databaser regelmæssigt for at sikre, at du har den mest nøjagtige og opdaterede information. Plus, med vores brugervenlige grænseflade, kan du hurtigt og nemt tjekke for stjålne biler fra dit eget hjem.",
  do_not_take_the_risk_of_buying_desc:
    "Så tag ikke risikoen ved at købe en stjålet bil eller køre i en uden at vide det - brug vores tjek af databasen for stjålne biler til at beskytte dig selv og din investering. Start din søgning nu! Det er helt gratis",
  help: "Hjælp",
  check_if_vin_correct: "Kontroller rigtigheden af VIN-nummeret",
  check_valuation: "Tjek en gratis bilvurdering",
  decode_vin_number: "Afkode VIN-nummer",
  additional_links: "Yderligere links",

  dangerous_models: "De sikreste modeller",
  most_popular: "De mest populære modeller",
  most_expensive: "De dyreste modeller",
  according_stats_desc:
    "Ifølge statistikker er ældre modeller med sikkerhedssystemer de mindst modtagelige for tyveri.",
  most_frequently_desc:
    "De hyppigst stjålne modeller er populære mærker og modeller, såsom Volkswagen Golf eller Ford Focus.",
  luxury_cars_such_as_desc:
    "Luksusbiler som Porsche, Lamborghini og Ferrari er også de mest sårbare over for tyveri.",
  alarm_system: "Alarmsystemer",
  alarm_system_desc:
    "Installation af en alarm og en sirene af tilstedeværelsen af flere niveauer af tyverisikring.",
  steering_wheel_locks: "Ratlåse",
  steering_wheel_locks_desc:
    "En god måde at beskytte din bil mod tyveri er at bruge ratlåse",
  hidding_keys: "Skjuler nøglerne",
  hidding_keys_desc:
    "Lad aldrig dine nøgler være synlige, da de kan friste en tyv.",
  parking_on_private_areas: "Parkering på privat grund",
  parking_on_private_areas_desc:
    "Hvis du foretrækker parkeringspladser på privat grund, kan du være sikker på, at dit køretøj er sikkert.",
  how_to_prevent_theft: "Sådan forhindrer du tyveri",
  secure_parking: "Sikker parkering",
  secure_parking_desc:
    "En af de bedste måder at beskytte din bil mod tyveri er at parkere den et sted, der er overvåget og oplyst om natten. Se efter parkeringspladser, der har kameraer, alarmsystemer eller personale udstationeret til at overvåge.",
  indoor_car_parking: "Overdækkede parkeringspladser",
  indoor_car_parking_desc:
    "Beskyt din bil mod vejret og tyveri ved at bruge den overdækkede parkering i byens centrum.",
  limited_availability: "Begrænset tilgængelighed",
  limited_availability_desc:
    "Vælg en parkeringsplads med begrænset adgang og adgangskoder, hvilket gør det svært for tyve.",
  megaparks: "Megaparker",
  megaparks_desc:
    "Bruger du en mega parkeringsplads, så sørg for at din bil er parkeret tæt ved indgangen og er godt synlig.",
  monitored_parking: "Overvåget parkeringsplads",
  monitored_parking_desc:
    "Se efter parkeringspladser, der overvåges af CCTV, og lås altid din bil, selvom du forlader den for en kort stund.",
  summary: "Resumé",
  summary_desc:
    "Biltyveri er et alvorligt problem på verdensplan. Stigningen i antallet af køretøjer på vejene er befordrende for kriminelles stigende aktivitet. Stjålne biler sælges ofte som reservedele eller eksporteres til udlandet, hvilket gør det vanskeligt for deres ejere at få dem tilbage. Politiorganisationer og statslige institutioner træffer adskillige tiltag for at forebygge og bekæmpe denne type kriminalitet, herunder forbedring af sikkerhedsteknologi og internationalt samarbejde om retsforfølgelse af kriminelle. Uddannelse og opmærksomhed blandt chauffører er også vigtig for at forhindre situationer, der favoriserer biltyveri",
};
