export const cs = {
  free_vehicle_check: "Kontrola vozidla zdarma",
  enter_vin_number: "Zadejte číslo VIN",
  must_be_17_characters: "Musí mít 17 znaků",
  check_vehicle: "Zkontrolujte vozidlo",
  buying_used_car_check_vehicle_desc:
    "Kupujete ojetý vůz? Zkontrolujte vozidlo v naší databázi!",
  database_have_records_desc:
    "V naší databázi máme přes 140 000 záznamů o odcizených vozidlech. Databáze je využívána mnoha institucemi, inspektory a výměnou informačních systémů o odcizených vozidlech.",
  checking_vehicle: "Kontrola vozidla",
  proccessing_please_wait: "Zpracovává se – čekejte prosím",
  please_wait_takes_moment: "Prosím, čekejte. Trvá to jen chvilku.",
  vehicle_not_found: "Vozidlo nenalezeno",
  vehicle_found: "Vozidlo nalezeno",
  vehicle_does_not_appear_database:
    "Vozidlo se v odcizené databázi neobjevuje.",
  vehicle_appear_database: "Vozidlo se objeví v databázi odcizených vozidel",
  check_another_vehicle: "Zkontrolujte jiné vozidlo",
  check_vehicle_history: "Zkontrolujte historii vozidla",
  get_more_information: "Získejte více informací",
  incidents_history: "Historie incidentů",
  mileage_reading: "Odečítání kilometrů",
  vehicle_inspections: "Kontroly vozidel",
  archival_photo: "Archivní foto",
  and_more: "a více",
  check_now: "Zkontroluj to teď",
  all_rights_reserved: "Všechna práva vyhrazena.",
  entered_vin_number: "Zadané VIN číslo",
  may_be_incorrect: "Může to být nesprávné",
  correct: "Opravit",
  check: "Šek",
  incorrect: "Nesprávný",
  check_again: "Zkontrolovat znovu",
  entered_vin_is_incorrect:
    "K danému VIN nejsou žádné údaje nebo je číslo neplatné",
  reenter_your_vin: "Zadejte prosím znovu své číslo vína",
  accept: "Přijmout",
  regulations: "Předpisy",
  back: "Zadní",
  last_checked_vins: "Nedávno zkontrolovaná čísla VIN",
  enter_plate: "SPZ",
  enter_country_code: "Kód země",
  value_cannot_be_empty: "Hodnota nemůže být prázdná",
  fast_and_reliable_stolen:
    "Rychlá a spolehlivá kontrola databáze odcizených aut",
  welcome_our_website_desc:
    "Vítejte na našem webu pro kontrolu <strong>databází odcizených vozů</strong> . Pokud máte podezření, že váš vůz byl odcizen, nebo se chcete <strong>před koupí odcizeného vozidla chránit</strong> , naše služba vám může pomoci.",
  using_our_advanced_search_tools_desc:
    "Pomocí našich pokročilých vyhledávacích nástrojů můžete pomocí několika kliknutí snadno zkontrolovat několik databází <strong>ukradených aut</strong> . Jednoduše zadejte VIN nebo SPZ dotyčného vozu a my prohledáme naši <strong>rozsáhlou databázi</strong> , abychom zjistili, zda nebyl nahlášen jako odcizený.",
  our_service_is_fast_desc:
    "Naše služba je rychlá, spolehlivá a snadno použitelná a naše databáze pravidelně aktualizujeme, abychom zajistili, že budete mít ty nejpřesnější a nejaktuálnější informace. Navíc s naším uživatelsky přívětivým rozhraním můžete rychle a snadno zkontrolovat odcizená auta z pohodlí vašeho domova.",
  do_not_take_the_risk_of_buying_desc:
    "Neriskujte tedy, že si koupíte kradené auto nebo ho budete řídit, aniž byste o tom věděli – využijte naši kontrolu databáze kradených aut, abyste ochránili sebe a svou investici. Začněte hledat hned teď! Je to zcela zdarma",
  help: "Pomoc",
  check_if_vin_correct: "Zkontrolujte správnost čísla VIN",
  check_valuation: "Podívejte se na bezplatné ocenění vozidla",
  decode_vin_number: "Dekódujte VIN číslo",
  additional_links: "Další odkazy",

  dangerous_models: "Nejbezpečnější modely",
  most_popular: "Nejoblíbenější modely",
  most_expensive: "Nejdražší modely",
  according_stats_desc:
    "Podle statistik jsou nejméně náchylné ke krádeži starší modely se zabezpečovacími systémy.",
  most_frequently_desc:
    "Nejčastěji kradenými modely jsou oblíbené značky a modely, jako je Volkswagen Golf nebo Ford Focus.",
  luxury_cars_such_as_desc:
    "Nejzranitelnější vůči krádežím jsou také luxusní vozy jako Porsche, Lamborghini a Ferrari.",
  alarm_system: "Poplašné systémy",
  alarm_system_desc:
    "Instalace alarmu a sirény o přítomnosti několika úrovní ochrany proti krádeži.",
  steering_wheel_locks: "Zámky řízení",
  steering_wheel_locks_desc:
    "Dobrým způsobem, jak ochránit své auto před krádeží, je použití zámků řízení",
  hidding_keys: "Skrývání klíčů",
  hidding_keys_desc:
    "Nikdy nenechávejte klíče na očích, protože by mohly pokoušet zloděje.",
  parking_on_private_areas: "Parkování na soukromém pozemku",
  parking_on_private_areas_desc:
    "Preferujete-li parkovací místa na soukromém pozemku, můžete si být jisti, že vaše vozidlo je v bezpečí.",
  how_to_prevent_theft: "Jak zabránit krádeži",
  secure_parking: "Bezpečné parkování",
  secure_parking_desc:
    "Jedním z nejlepších způsobů, jak ochránit své auto před krádeží, je zaparkovat ho na místě, které je v noci monitorováno a osvětleno. Hledejte parkovací místa, která mají kamery, poplašné systémy nebo personál, který bude monitorovat.",
  indoor_car_parking: "Krytá parkoviště",
  indoor_car_parking_desc:
    "Chraňte svůj vůz před povětrnostními vlivy a krádeží pomocí krytého parkoviště v centru města.",
  limited_availability: "Limitovaná dostupnost",
  limited_availability_desc:
    "Vyberte si parkoviště s omezeným přístupem a hesly, abyste to zloději znesnadnili.",
  megaparks: "Megaparky",
  megaparks_desc:
    "Pokud používáte mega parkoviště, ujistěte se, že vaše auto stojí blízko vchodu a je dobře viditelné.",
  monitored_parking: "Monitorované parkoviště",
  monitored_parking_desc:
    "Hledejte parkovací místa, která jsou monitorována kamerovým systémem a vždy své auto zamykejte, i když ho na krátkou chvíli opustíte.",
  summary: "souhrn",
  summary_desc:
    "Krádeže aut jsou vážným celosvětovým problémem. Nárůst počtu vozidel na silnicích přispívá ke zvyšující se aktivitě zločinců. Kradené vozy jsou často prodávány na díly nebo vyváženy do zahraničí, což jejich majitelům ztěžuje jejich zpětné získání. Policejní organizace a vládní instituce podnikají četná opatření k prevenci a boji proti tomuto typu zločinu, včetně zlepšování bezpečnostních technologií a mezinárodní spolupráce při stíhání zločinců. Vzdělávání a osvěta mezi řidiči jsou také důležité pro předcházení situacím, které zvýhodňují krádeže aut",
};
