<template>
  <div
    class="
      position-relative
      overflow-hidden
      p-3 p-md-5
      m-md-3
      text-center
      bg-grey
      rounded-3
      mb-4
    "
  >
    <div class="row text-center">
      <div class="col-lg-7 col-xl-8 overflow-hidden">
        <div class="card h-100 mb-4 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-bolder">{{ vinNumber }}</h4>
          </div>
          <div class="card-body">
            <img
              v-if="!stolen"
              width="128"
              height="128"
              src="../assets/img/ok-icon.png"
              :alt="t('vehicle_not_found')"
            />
            <img
              v-else-if="stolen"
              src="../assets/img/error-icon.png"
              :alt="t('vehicle_found')"
              width="128"
              height="128"
            />
            <h4 v-if="!stolen" class="my-4">
              {{ t("vehicle_does_not_appear_database") }}
            </h4>
            <h4 v-else-if="stolen" class="my-4">
              {{ t("vehicle_appear_database") }}
            </h4>
            <Button @click="checkAgain" classes="w-100 btn btn-lg mb-0">
              {{ t("check_another_vehicle") }}
            </Button>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-4 my-4 my-lg-0 overflow-hidden">
        <div class="card h-100 mb-4 rounded-3 shadow-sm">
          <div class="card-header py-3 text-white check-history-section">
            <h4 class="my-0 fw-normal">{{ t("check_vehicle_history") }}</h4>
          </div>
          <div class="card-body">
            <h5 class="card-title">{{ t("get_more_information") }}</h5>
            <h5 class="card-title">{{ vinNumber }}</h5>
            <ul class="list-unstyled mt-3 mb-4">
              <li>
                {{ t("incidents_history") }}
                <i v-if="precheckData.damages > 0" class="bi bi-check-lg"></i>
              </li>
              <li>
                {{ t("mileage_reading") }}
                <i v-if="precheckData.odometers > 0" class="bi bi-check-lg"></i>
              </li>
              <li>
                {{ t("vehicle_inspections") }}
                <i v-if="precheckData.events > 0" class="bi bi-check-lg"></i>
              </li>
              <li>
                {{ t("archival_photo") }}
                <i v-if="precheckData.photos > 0" class="bi bi-check-lg"></i>
              </li>
              <li v-if="!precheckStatus">... {{ t("and_more") }}</li>
            </ul>
            <a
              type="button"
              class="w-100 btn btn-lg check-history-btn"
              :href="`${partnerLink(lang)}/${vinNumber}`"
              target="_blank"
              >{{ t("check_now") }}!</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { partnerLink } from "../utils/config";
import { useRouter } from "vue-router";
import Button from "./Button";

export default {
  name: "ResultStolen",
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();

    const checkAgain = () => {
      router.push({
        name: "home",
      });
    };

    const precheckStatus = computed(() => {
      return store.getters.precheckStatus;
    });

    onMounted(async () => {
      const checkedVin = vinNumber.value || null;

      if (checkedVin === null) {
        // vin.value = router.currentRoute.value.params.vin;

        router.push({
          name: 'checkingVin',
        });
      }
    });

    const vinNumber = computed(() => {
      return store.state.checkedVin;
    });

    const precheckData = computed(() => {
      return store.state.precheck;
    });

    const stolen = computed(() => {
      return store.state.stolen;
    });

    const lang = computed(() => {
      return store.state.lang;
    });

    onUnmounted(() => {
      return store.dispatch("RESET_STATE");
    });

    return {
      vinNumber,
      checkAgain,
      t,
      precheckData,
      stolen,
      lang,
      partnerLink,
      precheckStatus,
    };
  },
};
</script>

<style scoped>
.check-history-section {
  background-color: #cc1e1f;
}
.check-history-btn {
  background-color: #cc1e1f;
  color: white;
}
</style>