<template>
  <footer class="my-md-5 border-top">
    <div class="container text-white py-4 py-lg-5">
      <div class="row">
        <div class="col-12 col-md-6 p-2">
          <h4 class="text-center text-md-start text-dark">
            {{ t("help") }}
          </h4>
          <ul class="list-inline d-flex flex-column text-center text-md-start">
            <li class="list-inline-item">
              <router-link class="regulations" to="/regulations">{{
                t("regulations")
              }}</router-link>
            </li>
          </ul>
        </div>

        <div class="col-12 col-md-6 p-2">
          <h4 class="text-center text-md-start text-dark">
            {{ t("additional_links") }}
          </h4>
          <ul class="list-inline d-flex flex-column text-center text-md-start">
            <li class="list-inline-item">
              <a href="https://www.vincorrect.com/"
                >VINcorrect ({{ t("check_if_vin_correct") }})</a
              >
            </li>
            <li class="list-inline-item">
              <a href="https://www.vinvaluation.com/"
                >VINValuation ({{ t("check_valuation") }})</a
              >
            </li>
            <li class="list-inline-item">
              <a href="https://www.vindecoder.pl/"
                >VINDecoder ({{ t("decode_vin_number") }})</a
              >
            </li>
            <li v-show="lang == 'pl'" class="list-inline-item">
              <a href="https://easyvin.pl/"
                >EasyVIN (Sprawdź darmową historię)</a
              >
            </li>
            <li v-show="lang == 'pl'" class="list-inline-item">
              <a href="https://www.autobaza.pl/zglos-kradziez-pojazdu"
                >Autobaza (zgłoś kradzież pojazdu)</a
              >
              <span class="text-dark fw-bold"> lub </span>
              <a href="https://www.autobaza.pl/"
                >Kup raport histroii pojazdu</a
              >
            </li>
          </ul>
        </div>
      </div>
      <hr class="text-dark" />
      <p class="mb-0 text-center text-md-start text-dark">
        Copyright © 2022 Car Data Experts
      </p>
    </div>
  </footer>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "Footer",
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const lang = computed(() => {
      return store.state.lang;
    });

    return {
      t,
      lang,
    };
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: underline;
}
</style>