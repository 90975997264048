<template>
  <Progress />
</template>

<script>
import Progress from "../components/Progress";

export default {
  name: "CheckingVin",
  components: {
    Progress,
  },
};
</script>