export const es = {
  free_vehicle_check: "revisión de vehículos gratis",
  enter_vin_number: "Ingrese el número de VIN",
  must_be_17_characters: "Debe tener 17 caracteres",
  check_vehicle: "Verificar vehículo",
  buying_used_car_check_vehicle_desc:
    "¿Estás comprando un auto usado? ¡Consulta vehículo en nuestra base de datos!",
  database_have_records_desc:
    "En nuestra base de datos contamos con más de 140.000 registros de vehículos robados. La base de datos es utilizada por muchas instituciones, inspectores y sistemas de intercambio de información sobre vehículos robados.",
  checking_vehicle: "Comprobación del vehículo",
  proccessing_please_wait: "Procesando .. por favor espere",
  please_wait_takes_moment: "Espere por favor. Sólo toma un momento.",
  vehicle_not_found: "Vehículo no encontrado",
  vehicle_found: "Vehículo encontrado",
  vehicle_does_not_appear_database:
    "El vehículo no aparece en la base de datos robados.",
  vehicle_appear_database:
    "El vehículo aparece en la base de datos de vehículos robados",
  check_another_vehicle: "Comprobar otro vehículo",
  check_vehicle_history: "Verifique el historial del vehículo",
  get_more_information: "Obtener mas informacion",
  incidents_history: "Historial de incidentes",
  mileage_reading: "lectura de kilometraje",
  vehicle_inspections: "Inspecciones de vehículos",
  archival_photo: "foto de archivo",
  and_more: "y más",
  check_now: "Revisalo ahora",
  all_rights_reserved: "Todos los derechos reservados.",
  entered_vin_number: "El número VIN ingresado",
  may_be_incorrect: "puede ser incorrecto",
  correct: "Correcto",
  check: "Controlar",
  incorrect: "Incorrecto",
  check_again: "Revisar otra vez",
  entered_vin_is_incorrect:
    "No hay datos para el VIN dado o el número no es válido",
  reenter_your_vin: "Vuelva a ingresar su número de vin",
  accept: "Aceptar",
  regulations: "Reglamento",
  back: "Atrás",
  last_checked_vins: "VIN revisados ​​recientemente",
  enter_plate: "Matrícula del vehículo",
  enter_country_code: "Código de país",
  value_cannot_be_empty: "El valor no puede estar vacío.",
  fast_and_reliable_stolen:
    "Verificación rápida y confiable de la base de datos de autos robados",
  welcome_our_website_desc:
    "Bienvenido a nuestro sitio web para verificar <strong>las bases de datos de autos robados</strong> . Si sospecha que le han robado su automóvil o desea <strong>protegerse de la compra de un vehículo robado</strong> , nuestro servicio puede ayudarlo.",
  using_our_advanced_search_tools_desc:
    "Usando nuestras herramientas de búsqueda avanzada, puede verificar fácilmente múltiples bases de datos en busca de <strong>autos robados</strong> con solo unos pocos clics. Simplemente ingrese el VIN o el número de placa del automóvil en cuestión, y buscaremos en nuestra <strong>extensa base de datos</strong> para ver si ha sido denunciado como robado.",
  our_service_is_fast_desc:
    "Nuestro servicio es rápido, confiable y fácil de usar, y actualizamos nuestras bases de datos periódicamente para garantizar que tenga la información más precisa y actualizada. Además, con nuestra interfaz fácil de usar, puede verificar rápida y fácilmente si hay autos robados desde la comodidad de su hogar.",
  do_not_take_the_risk_of_buying_desc:
    "Así que no se arriesgue a comprar un automóvil robado o conducir uno sin saberlo: utilice nuestra verificación de la base de datos de automóviles robados para protegerse a sí mismo y a su inversión. ¡Comience su búsqueda ahora! es totalmente gratis",
  help: "Ayudar",
  check_if_vin_correct: "Verifique la exactitud del número VIN",
  check_valuation: "Echa un vistazo a una tasación de vehículos gratis",
  decode_vin_number: "Decodificar número VIN",
  additional_links: "Enlaces adicionales",

  dangerous_models: "Los modelos más seguros",
  most_popular: "Los modelos más populares",
  most_expensive: "Los modelos más caros",
  according_stats_desc:
    "Según las estadísticas, los modelos más antiguos con sistemas de seguridad son los menos susceptibles de robo.",
  most_frequently_desc:
    "Los modelos robados con mayor frecuencia son marcas y modelos populares, como Volkswagen Golf o Ford Focus.",
  luxury_cars_such_as_desc:
    "Los autos de lujo como Porsche, Lamborghini y Ferrari también son los más vulnerables al robo.",
  alarm_system: "Sistemas de alarma",
  alarm_system_desc:
    "Instalación de alarma y sirena de presencia de varios niveles de protección antirrobo.",
  steering_wheel_locks: "Bloqueos de dirección",
  steering_wheel_locks_desc:
    "Una buena manera de proteger su automóvil contra robos es usar bloqueos de dirección",
  hidding_keys: "escondiendo las llaves",
  hidding_keys_desc:
    "Nunca deje sus llaves a la vista, ya que pueden tentar a un ladrón.",
  parking_on_private_areas: "Estacionamiento en terrenos privados",
  parking_on_private_areas_desc:
    "Al preferir los espacios de estacionamiento en terrenos privados, puede estar seguro de que su vehículo está seguro.",
  how_to_prevent_theft: "Cómo prevenir el robo",
  secure_parking: "Aparcamiento seguro",
  secure_parking_desc:
    "Una de las mejores maneras de proteger su automóvil contra robos es estacionarlo en un lugar vigilado e iluminado por la noche. Busque espacios de estacionamiento que tengan cámaras, sistemas de alarma o personal asignado para monitorear.",
  indoor_car_parking: "Aparcamientos cubiertos",
  indoor_car_parking_desc:
    "Protege tu coche de las inclemencias del tiempo y los robos utilizando el parking cubierto en el centro de la ciudad.",
  limited_availability: "Disponibilidad limitada",
  limited_availability_desc:
    "Elija un estacionamiento con acceso restringido y contraseñas, que dificulte a los ladrones.",
  megaparks: "megaparques",
  megaparks_desc:
    "Si usa un mega estacionamiento, asegúrese de que su automóvil esté estacionado cerca de la entrada y sea claramente visible.",
  monitored_parking: "Aparcamiento vigilado",
  monitored_parking_desc:
    "Busque espacios de estacionamiento que estén monitoreados por circuito cerrado de televisión y siempre cierre con llave su automóvil, incluso si lo deja por un corto tiempo.",
  summary: "Resumen",
  summary_desc:
    "El robo de automóviles es un problema grave en todo el mundo. El aumento del número de vehículos en las carreteras favorece el aumento de la actividad de los delincuentes. Los automóviles robados a menudo se venden por repuestos o se exportan al extranjero, lo que dificulta que sus dueños los recuperen. Las organizaciones policiales y las instituciones gubernamentales toman numerosas acciones para prevenir y combatir este tipo de delitos, incluida la mejora de la tecnología de seguridad y la cooperación internacional para enjuiciar a los delincuentes. La educación y la concienciación de los conductores también son importantes para prevenir situaciones que favorezcan el robo de vehículos",
};
