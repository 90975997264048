<template>
  <div class="languages">
    <span v-for="(country, index) in countries" :key="index" class="me-1">
      <img
        :src="require(`../assets/img/languages/${country}.png`)"
        class="img-fluid"
        :class="{ active: activeLang == country }"
        :alt="country"
        @click="setLang(country)"
      />
    </span>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useStore } from 'vuex';

export default {
  name: "LanguageSelector",
  setup() {
    const store = useStore();
    const { locale } = useI18n();
    let activeLang = ref("");

    const countries = {
      pl: "pl",
      en: "en",
      de: "de",
      cs: "cs",
      lt: "lt",
      fr: "fr",
      es: "es",
      ro: "ro",
      // ru: "ru",
      nl: "nl",
      hu: "hu",
      bg: "bg",
      da: "da",
      el: "el",
      it: "it",
      no: "no",
      pt: "pt",
      sk: "sk",
      sr: "sr",
      sv: "sv",
      tr: "tr",
      uk: "uk",
      ar: "ar",
    };
    const setLang = (lang) => {
      activeLang.value = lang;
      locale.value = lang;
      store.commit('updateLang', lang);
    };

    onMounted(async () => {
      await store.dispatch('SET_LANG');
      const lang = store.state.lang;
      activeLang.value = lang;
    });

    return {
      activeLang,
      setLang,
      countries,
    };
  },
};
</script>

<style scoped>
img:hover {
  cursor: pointer;
}

.active {
  transform: scale(1.1);
}
</style>