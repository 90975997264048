export const fr = {
  free_vehicle_check: "Vérification gratuite du véhicule",
  enter_vin_number: "Entrez le numéro VIN",
  must_be_17_characters: "Doit contenir 17 caractères",
  check_vehicle: "Vérifier le véhicule",
  buying_used_car_check_vehicle_desc:
    "Vous achetez une voiture d'occasion ? Vérifiez le véhicule dans notre base de données!",
  database_have_records_desc:
    "Dans notre base de données, nous avons plus de 140 000 enregistrements de véhicules volés. La base de données est utilisée par de nombreuses institutions, inspecteurs et systèmes d'échange d'informations sur les véhicules volés.",
  checking_vehicle: "Vérification du véhicule",
  proccessing_please_wait: "Traitement - veuillez patienter",
  please_wait_takes_moment:
    "S'il vous plaît, attendez. Cela prend seulement un moment.",
  vehicle_not_found: "Véhicule introuvable",
  vehicle_found: "Véhicule trouvé",
  vehicle_does_not_appear_database:
    "Le véhicule n'apparaît pas dans la base de données volée.",
  vehicle_appear_database:
    "Le véhicule apparaît dans la base de données des véhicules volés",
  check_another_vehicle: "Vérifier un autre véhicule",
  check_vehicle_history: "Vérifier l'historique du véhicule",
  get_more_information: "Se procurer plus d'information",
  incidents_history: "Historique des incidents",
  mileage_reading: "Lecture du kilométrage",
  vehicle_inspections: "Inspections de véhicules",
  archival_photo: "Photo d'archive",
  and_more: "et plus",
  check_now: "Vérifie maintenant",
  all_rights_reserved: "Tous les droits sont réservés.",
  entered_vin_number: "Le numéro VIN saisi",
  may_be_incorrect: "C'est peut-être incorrect",
  correct: "Correct",
  check: "Vérifier",
  incorrect: "Incorrect",
  check_again: "Revérifier",
  entered_vin_is_incorrect:
    "Il n'y a pas de données pour le VIN donné ou le numéro est invalide",
  reenter_your_vin: "Veuillez ressaisir votre numéro vin",
  accept: "J'accepte",
  regulations: "Règlements",
  back: "Arrière",
  last_checked_vins: "NIV récemment vérifiés",
  enter_plate: "Plaque d'immatriculation",
  enter_country_code: "Code postal",
  value_cannot_be_empty: "La valeur ne peut pas être vide",
  fast_and_reliable_stolen:
    "Vérification rapide et fiable de la base de données des voitures volées",
  welcome_our_website_desc:
    "Bienvenue sur notre site Web pour vérifier <strong>les bases de données de voitures volées</strong> . Si vous soupçonnez que votre voiture a été volée ou si vous souhaitez vous <strong>protéger contre l'achat d'un véhicule volé</strong> , notre service peut vous aider.",
  using_our_advanced_search_tools_desc:
    "Grâce à nos outils de recherche avancés, vous pouvez facilement vérifier plusieurs bases de données pour <strong>les voitures volées</strong> en quelques clics. Entrez simplement le VIN ou le numéro de plaque de la voiture en question, et nous rechercherons dans notre <strong>vaste base de données</strong> pour voir si elle a été signalée comme volée.",
  our_service_is_fast_desc:
    "Notre service est rapide, fiable et facile à utiliser, et nous mettons régulièrement à jour nos bases de données pour nous assurer que vous disposez des informations les plus précises et les plus à jour. De plus, grâce à notre interface conviviale, vous pouvez vérifier rapidement et facilement les voitures volées dans le confort de votre foyer.",
  do_not_take_the_risk_of_buying_desc:
    "Ne prenez donc pas le risque d'acheter une voiture volée ou d'en conduire une sans le savoir - utilisez notre vérification de la base de données des voitures volées pour vous protéger et protéger votre investissement. Commencez votre recherche maintenant ! C'est totalement gratuit",
  help: "Aider",
  check_if_vin_correct: "Vérifier l'exactitude du numéro VIN",
  check_valuation: "Découvrez une estimation gratuite de votre véhicule",
  decode_vin_number: "Décoder le numéro VIN",
  additional_links: "Liens supplémentaires",

  dangerous_models: "Les modèles les plus sûrs",
  most_popular: "Les modèles les plus populaires",
  most_expensive: "Les modèles les plus chers",
  according_stats_desc:
    "Selon les statistiques, les anciens modèles avec systèmes de sécurité sont les moins susceptibles d'être volés.",
  most_frequently_desc:
    "Les modèles les plus fréquemment volés sont des marques et des modèles populaires, tels que Volkswagen Golf ou Ford Focus.",
  luxury_cars_such_as_desc:
    "Les voitures de luxe telles que Porsche, Lamborghini et Ferrari sont également les plus vulnérables au vol.",
  alarm_system: "Systèmes d'alarme",
  alarm_system_desc:
    "Installation d'une alarme et d'une sirène de présence de plusieurs niveaux de protection antivol.",
  steering_wheel_locks: "Antivols de direction",
  steering_wheel_locks_desc:
    "Un bon moyen de protéger votre voiture contre le vol est d'utiliser des antivols de direction",
  hidding_keys: "Cacher les clés",
  hidding_keys_desc:
    "Ne laissez jamais vos clés à la vue car elles pourraient tenter un voleur.",
  parking_on_private_areas: "Stationnement sur terrain privé",
  parking_on_private_areas_desc:
    "Préférant les places de stationnement sur terrain privé, vous pouvez être sûr que votre véhicule est en sécurité.",
  how_to_prevent_theft: "Comment prévenir le vol",
  secure_parking: "Stationnement sécurisé",
  secure_parking_desc:
    "L'un des meilleurs moyens de protéger votre voiture contre le vol est de la garer dans un endroit surveillé et éclairé la nuit. Recherchez les places de stationnement équipées de caméras, de systèmes d'alarme ou de personnel affecté à la surveillance.",
  indoor_car_parking: "Parkings couverts",
  indoor_car_parking_desc:
    "Protégez votre voiture des intempéries et du vol en utilisant le parking couvert du centre-ville.",
  limited_availability: "Disponibilité limitée",
  limited_availability_desc:
    "Choisissez un parking avec un accès restreint et des mots de passe, ce qui rend la tâche difficile aux voleurs.",
  megaparks: "Mégaparcs",
  megaparks_desc:
    "Si vous utilisez un méga parking, assurez-vous que votre voiture est garée près de l'entrée et bien visible.",
  monitored_parking: "Parking surveillé",
  monitored_parking_desc:
    "Cherchez des places de stationnement surveillées par CCTV et verrouillez toujours votre voiture, même si vous la quittez pour une courte période.",
  summary: "Résumé",
  summary_desc:
    "Le vol de voiture est un problème grave dans le monde entier. L'augmentation du nombre de véhicules sur les routes est propice à l'activité croissante des criminels. Les voitures volées sont souvent vendues pour des pièces ou exportées à l'étranger, ce qui rend difficile pour leurs propriétaires de les récupérer. Les organisations policières et les institutions gouvernementales prennent de nombreuses mesures pour prévenir et combattre ce type de crime, notamment en améliorant les technologies de sécurité et la coopération internationale dans la poursuite des criminels. L'éducation et la sensibilisation des conducteurs sont également importantes pour prévenir les situations qui favorisent le vol de voiture",
};
