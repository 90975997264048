<template>
  <div>
    <IncorrectVin />
  </div>
</template>

<script>
import IncorrectVin from "../components/IncorrectVin";

export default {
  name: "ErrResult",
  components: {
    IncorrectVin,
  },
};
</script>

<style>
</style>