<template>
  <div class="row w-100 m-0"> 
    <div
      class="
        col-12
        cookie
        d-flex
        flex-column
        justify-content-center
        shadow-4-strong
      "
    >
      <div v-html="t('cookies_desc', { email: 'info@cardataexperts.com' })"></div>
      <Button
        @click="showCookie"
        color="light"
        class="d-flex align-self-center justify-content-center mt-3 mb-3"
        >{{ t('accept') }}</Button
      >
    </div>
  </div>
</template>

<script>
import Button from "./Button";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "Cookies",
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const { cookies } = useCookies();
    const { t } = useI18n();

    const showCookie = () => {
      cookies.set("stolenCars", "policy_accepted");
      store.commit("updateShowCookie", false);
    };

    return {
      showCookie,
      t
    };
  },
};
</script>

<style scoped>
.cookie {
  width: 100vw;
  height: auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    234deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 128, 180, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
}

p {
  font-size: 12px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}
</style>