export const sk = {
  free_vehicle_check: "Bezplatná kontrola vozidla",
  enter_vin_number: "Zadajte číslo VIN",
  must_be_17_characters: "Musí mať 17 znakov",
  check_vehicle: "Skontrolujte vozidlo",
  buying_used_car_check_vehicle_desc:
    "Kupujete ojazdené auto? Skontrolujte vozidlo v našej databáze!",
  database_have_records_desc:
    "V našej databáze máme viac ako 140 000 záznamov o odcudzených vozidlách. Databázu využívajú mnohé inštitúcie, inšpektori a výmena informačných systémov o odcudzených vozidlách.",
  checking_vehicle: "Kontrola vozidla",
  proccessing_please_wait: "Spracováva sa – čakajte prosím",
  please_wait_takes_moment: "Prosím čakajte. Trvá to len chvíľu.",
  vehicle_not_found: "Vozidlo sa nenašlo",
  vehicle_found: "Vozidlo nájdené",
  vehicle_does_not_appear_database:
    "Vozidlo nefiguruje v databáze odcudzených osôb.",
  vehicle_appear_database: "Vozidlo sa objaví v databáze ukradnutých vozidiel",
  check_another_vehicle: "Skontrolujte iné vozidlo",
  check_vehicle_history: "Skontrolujte históriu vozidla",
  get_more_information: "Získajte viac informácií",
  incidents_history: "História incidentov",
  mileage_reading: "Čítanie kilometrov",
  vehicle_inspections: "Kontroly vozidiel",
  archival_photo: "Archívne foto",
  and_more: "a viac",
  check_now: "Skontroluj teraz",
  all_rights_reserved: "Všetky práva vyhradené.",
  entered_vin_number: "Zadané VIN číslo",
  may_be_incorrect: "Môže to byť nesprávne",
  correct: "Správne",
  check: "Skontrolujte",
  incorrect: "Nesprávne",
  check_again: "Skontroluj znova",
  entered_vin_is_incorrect:
    "K danému VIN nie sú žiadne údaje alebo je číslo neplatné",
  reenter_your_vin: "Znova zadajte svoje číslo vína",
  accept: "súhlasiť",
  regulations: "nariadenia",
  back: "späť",
  last_checked_vins: "Nedávno skontrolované VIN",
  enter_plate: "ŠPZ vozidla",
  enter_country_code: "Kód krajiny",
  value_cannot_be_empty: "Hodnota nemôže byť prázdna",
  fast_and_reliable_stolen:
    "Rýchla a spoľahlivá kontrola databázy ukradnutých áut",
  welcome_our_website_desc:
    "Vitajte na našej stránke pre kontrolu <strong>databáz ukradnutých áut</strong> . Ak máte podozrenie, že vaše auto bolo odcudzené, alebo sa chcete <strong>pred kúpou ukradnutého vozidla chrániť</strong> , naša služba vám môže pomôcť.",
  using_our_advanced_search_tools_desc:
    "Pomocou našich pokročilých vyhľadávacích nástrojov môžete jednoducho niekoľkými kliknutiami skontrolovať viacero databáz pre <strong>ukradnuté autá</strong> . Stačí zadať VIN alebo ŠPZ príslušného auta a my prehľadáme v našej <strong>rozsiahlej databáze</strong> , či nebolo nahlásené ako odcudzené.",
  our_service_is_fast_desc:
    "Naša služba je rýchla, spoľahlivá a ľahko použiteľná a naše databázy pravidelne aktualizujeme, aby sme zaistili, že budete mať čo najpresnejšie a najaktuálnejšie informácie. Navyše, s naším užívateľsky prívetivým rozhraním môžete rýchlo a jednoducho skontrolovať kradnuté autá z pohodlia vášho domova.",
  do_not_take_the_risk_of_buying_desc:
    "Takže neriskujte, že si kúpite ukradnuté auto alebo ho budete riadiť bez toho, aby ste o tom vedeli – použite našu kontrolu databázy ukradnutých áut, aby ste ochránili seba a svoju investíciu. Začnite hľadať hneď teraz! Je to úplne zadarmo",
  help: "Pomoc",
  check_if_vin_correct: "Skontrolujte správnosť čísla VIN",
  check_valuation: "Pozrite si bezplatné ocenenie vozidla",
  decode_vin_number: "Dekódujte VIN číslo",
  additional_links: "Ďalšie odkazy",

  dangerous_models: "Najbezpečnejšie modely",
  most_popular: "Najobľúbenejšie modely",
  most_expensive: "Najdrahšie modely",
  according_stats_desc:
    "Podľa štatistík sú staršie modely s bezpečnostnými systémami najmenej náchylné na krádež.",
  most_frequently_desc:
    "Najčastejšie sa kradnú modely populárnych značiek a modelov, ako napríklad Volkswagen Golf alebo Ford Focus.",
  luxury_cars_such_as_desc:
    "Luxusné autá ako Porsche, Lamborghini a Ferrari sú tiež najzraniteľnejšie voči krádeži.",
  alarm_system: "Poplachové systémy",
  alarm_system_desc:
    "Inštalácia alarmu a sirény o prítomnosti niekoľkých úrovní ochrany proti krádeži.",
  steering_wheel_locks: "Zámky riadenia",
  steering_wheel_locks_desc:
    "Dobrým spôsobom, ako ochrániť auto pred krádežou, je použiť zámky riadenia",
  hidding_keys: "Skrytie kľúčov",
  hidding_keys_desc:
    "Nikdy nenechávajte kľúče na očiach, pretože môžu pokušiť zlodeja.",
  parking_on_private_areas: "Parkovanie na súkromnom pozemku",
  parking_on_private_areas_desc:
    "Ak uprednostňujete parkovacie miesta na súkromnom pozemku, môžete si byť istí, že vaše vozidlo je v bezpečí.",
  how_to_prevent_theft: "Ako zabrániť krádeži",
  secure_parking: "Bezpečné parkovanie",
  secure_parking_desc:
    "Jedným z najlepších spôsobov, ako ochrániť auto pred krádežou, je zaparkovať ho na mieste, ktoré je v noci monitorované a osvetlené. Hľadajte parkovacie miesta s kamerami, poplašnými systémami alebo personálom, ktorý bude monitorovať.",
  indoor_car_parking: "Kryté parkoviská",
  indoor_car_parking_desc:
    "Chráňte svoje auto pred počasím a krádežou využitím krytého parkoviska v centre mesta.",
  limited_availability: "Obmedzená dostupnosť",
  limited_availability_desc:
    "Vyberte si parkovisko s obmedzeným prístupom a heslami, čo sťaží zlodejom.",
  megaparks: "Megaparky",
  megaparks_desc:
    "Ak využívate mega parkovisko, uistite sa, že vaše auto je zaparkované blízko vchodu a je dobre viditeľné.",
  monitored_parking: "Monitorované parkovisko",
  monitored_parking_desc:
    "Hľadajte parkovacie miesta, ktoré sú monitorované kamerovým systémom a vždy zamknite svoje auto, aj keď ho na krátku chvíľu opustíte.",
  summary: "Zhrnutie",
  summary_desc:
    "Krádeže áut sú vážnym celosvetovým problémom. Nárast počtu vozidiel na cestách prispieva k zvyšujúcej sa aktivite zločincov. Ukradnuté autá sa často predávajú na súčiastky alebo sa vyvážajú do zahraničia, čo ich majiteľom sťažuje ich vymáhanie. Policajné organizácie a vládne inštitúcie prijímajú množstvo opatrení na prevenciu a boj proti tomuto typu zločinu, vrátane zlepšovania bezpečnostných technológií a medzinárodnej spolupráce pri stíhaní zločincov. Vzdelávanie a informovanosť vodičov sú tiež dôležité, aby sa predišlo situáciám, ktoré uprednostňujú krádeže áut",
};
