export const de = {
  free_vehicle_check: "Kostenloser Fahrzeugcheck",
  enter_vin_number: "Fahrgestellnummer eingeben",
  must_be_17_characters: "Muss 17 Zeichen lang sein",
  check_vehicle: "Fahrzeug prüfen",
  buying_used_car_check_vehicle_desc:
    "Sie kaufen einen Gebrauchtwagen? Fahrzeug in unserer Datenbank prüfen!",
  database_have_records_desc:
    "In unserer Datenbank haben wir über 140.000 Aufzeichnungen über gestohlene Fahrzeuge. Die Datenbank wird von vielen Institutionen, Inspektoren und Informationsaustauschsystemen über gestohlene Fahrzeuge verwendet.",
  checking_vehicle: "Fahrzeug prüfen",
  proccessing_please_wait: "Verarbeite .. Bitte warten",
  please_wait_takes_moment: "Warten Sie mal. Es dauert nur einen Moment.",
  vehicle_not_found: "Fahrzeug nicht gefunden",
  vehicle_found: "Fahrzeug gefunden",
  vehicle_does_not_appear_database:
    "Das Fahrzeug erscheint nicht in der gestohlenen Datenbank.",
  vehicle_appear_database:
    "Das Fahrzeug erscheint in der Datenbank für gestohlene Fahrzeuge",
  check_another_vehicle: "Überprüfen Sie ein anderes Fahrzeug",
  check_vehicle_history: "Überprüfen Sie die Fahrzeughistorie",
  get_more_information: "Mehr Informationen bekommen",
  incidents_history: "Geschichte der Vorfälle",
  mileage_reading: "Kilometerstand lesen",
  vehicle_inspections: "Fahrzeuginspektionen",
  archival_photo: "Archivfoto",
  and_more: "und mehr",
  check_now: "Jetzt prüfen",
  all_rights_reserved: "Alle Rechte vorbehalten.",
  entered_vin_number: "Die eingegebene Fahrgestellnummer",
  may_be_incorrect: "Es kann falsch sein",
  correct: "Richtig",
  check: "Überprüfen",
  incorrect: "Falsch",
  check_again: "Erneut überprüfen",
  entered_vin_is_incorrect:
    "Für die angegebene Fahrgestellnummer sind keine Daten vorhanden oder die Nummer ist ungültig",
  reenter_your_vin: "Bitte geben Sie Ihre Fahrgestellnummer erneut ein",
  accept: "Annehmen",
  regulations: "Vorschriften",
  back: "Zurück",
  last_checked_vins: "Kürzlich VINs überprüft",
  enter_plate: "Kfz-Kennzeichen",
  enter_country_code: "Landesvorwahl",
  value_cannot_be_empty: "Der Wert darf nicht leer sein",
  fast_and_reliable_stolen:
    "Schneller und zuverlässiger Datenbankcheck für gestohlene Autos",
  welcome_our_website_desc:
    "Willkommen auf unserer Website zum Überprüfen <strong>von Datenbanken für gestohlene Autos</strong> . Wenn Sie vermuten, dass Ihr Auto gestohlen wurde, oder Sie <strong>sich vor dem Kauf eines gestohlenen Fahrzeugs schützen</strong> möchten, kann Ihnen unser Service helfen.",
  using_our_advanced_search_tools_desc:
    "Mit unseren erweiterten Suchwerkzeugen können Sie mit nur wenigen Klicks mehrere Datenbanken nach <strong>gestohlenen Autos</strong> durchsuchen. Geben Sie einfach die Fahrgestellnummer oder das Kennzeichen des betreffenden Autos ein, und wir durchsuchen unsere <strong>umfangreiche Datenbank</strong> , um festzustellen, ob es als gestohlen gemeldet wurde.",
  our_service_is_fast_desc:
    "Unser Service ist schnell, zuverlässig und benutzerfreundlich, und wir aktualisieren unsere Datenbanken regelmäßig, um sicherzustellen, dass Sie über die genauesten und aktuellsten Informationen verfügen. Außerdem können Sie mit unserer benutzerfreundlichen Oberfläche bequem von zu Hause aus schnell und einfach nach gestohlenen Autos suchen.",
  do_not_take_the_risk_of_buying_desc:
    "Gehen Sie also nicht das Risiko ein, ein gestohlenes Auto zu kaufen oder eines zu fahren, ohne es zu wissen – nutzen Sie unseren Datenbankcheck für gestohlene Autos, um sich und Ihre Investition zu schützen. Starten Sie jetzt Ihre Suche! Es ist völlig kostenlos",
  help: "Hilfe",
  check_if_vin_correct: "Überprüfen Sie die Korrektheit der Fahrgestellnummer",
  check_valuation: "Sehen Sie sich eine kostenlose Fahrzeugbewertung an",
  decode_vin_number: "Fahrgestellnummer entschlüsseln",
  additional_links: "Zusätzliche Links",

  dangerous_models: "Die sichersten Modelle",
  most_popular: "Die beliebtesten Modelle",
  most_expensive: "Die teuersten Modelle",
  according_stats_desc:
    "Laut Statistik sind ältere Modelle mit Sicherheitssystemen am wenigsten anfällig für Diebstahl.",
  most_frequently_desc:
    "Die am häufigsten gestohlenen Modelle sind beliebte Marken und Modelle wie Volkswagen Golf oder Ford Focus.",
  luxury_cars_such_as_desc:
    "Auch Luxusautos wie Porsche, Lamborghini und Ferrari sind am stärksten von Diebstählen betroffen.",
  alarm_system: "Alarmsysteme",
  alarm_system_desc:
    "Installation eines Alarms und einer Sirene, die das Vorhandensein mehrerer Diebstahlschutzstufen anzeigt.",
  steering_wheel_locks: "Lenksperren",
  steering_wheel_locks_desc:
    "Eine gute Möglichkeit, Ihr Auto vor Diebstahl zu schützen, ist die Verwendung von Lenkradschlössern",
  hidding_keys: "Die Schlüssel verstecken",
  hidding_keys_desc:
    "Lassen Sie Ihre Schlüssel niemals offen liegen, da sie einen Dieb in Versuchung führen könnten.",
  parking_on_private_areas: "Parken auf Privatgrundstück",
  parking_on_private_areas_desc:
    "Wenn Sie Parkplätze auf Privatgrundstücken bevorzugen, können Sie sicher sein, dass Ihr Fahrzeug sicher ist.",
  how_to_prevent_theft: "So verhindern Sie Diebstahl",
  secure_parking: "Sicheres Parken",
  secure_parking_desc:
    "Eine der besten Möglichkeiten, Ihr Auto vor Diebstahl zu schützen, besteht darin, es an einem überwachten und nachts beleuchteten Ort zu parken. Suchen Sie nach Parkplätzen, die über Kameras, Alarmsysteme oder Personal zur Überwachung verfügen.",
  indoor_car_parking: "Überdachte Parkplätze",
  indoor_car_parking_desc:
    "Schützen Sie Ihr Auto vor Witterungseinflüssen und Diebstahl, indem Sie die überdachten Parkplätze im Stadtzentrum nutzen.",
  limited_availability: "Begrenzte Verfügbarkeit",
  limited_availability_desc:
    "Wählen Sie einen Parkplatz mit eingeschränktem Zugang und Passwörtern, was es Dieben schwer macht.",
  megaparks: "Megaparks",
  megaparks_desc:
    "Wenn Sie ein Mega-Parkhaus nutzen, achten Sie darauf, dass Ihr Auto in der Nähe der Einfahrt geparkt und gut sichtbar ist.",
  monitored_parking: "Überwachter Parkplatz",
  monitored_parking_desc:
    "Suchen Sie nach videoüberwachten Parkplätzen und schließen Sie Ihr Auto immer ab, auch wenn Sie es für kurze Zeit stehen lassen.",
  summary: "Zusammenfassung",
  summary_desc:
    "Autodiebstahl ist weltweit ein ernstes Problem. Die Zunahme der Zahl der Fahrzeuge auf den Straßen begünstigt die zunehmende Aktivität von Kriminellen. Gestohlene Autos werden oft gegen Ersatzteile verkauft oder ins Ausland exportiert, was es für ihre Besitzer schwierig macht, sie wiederzubekommen. Polizeiorganisationen und staatliche Institutionen ergreifen zahlreiche Maßnahmen zur Verhütung und Bekämpfung dieser Art von Kriminalität, darunter die Verbesserung der Sicherheitstechnologie und die internationale Zusammenarbeit bei der Strafverfolgung. Auch Aufklärung und Sensibilisierung der Autofahrer sind wichtig, um Situationen vorzubeugen, die einen Autodiebstahl begünstigen",
};
