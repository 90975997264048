export const tr = {
  free_vehicle_check: "Ücretsiz araç kontrolü",
  enter_vin_number: "VIN numarasını girin",
  must_be_17_characters: "17 karakter uzunluğunda olmalıdır",
  check_vehicle: "Aracı kontrol edin",
  buying_used_car_check_vehicle_desc:
    "Kullanılmış bir araba mı satın alıyorsunuz? Veritabanımızdaki aracı kontrol edin!",
  database_have_records_desc:
    "Veritabanımızda 140.000'den fazla çalıntı araç kaydı var. Veritabanı birçok kurum, müfettiş ve çalıntı araçlarla ilgili bilgi alışverişi sistemleri tarafından kullanılmaktadır.",
  checking_vehicle: "Araç kontrol ediliyor",
  proccessing_please_wait: "İşleniyor .. Lütfen bekleyin",
  please_wait_takes_moment: "Lütfen bekle. Sadece bir an sürer.",
  vehicle_not_found: "Araç bulunamadı",
  vehicle_found: "Araç bulundu",
  vehicle_does_not_appear_database: "Araç çalıntı veritabanında görünmüyor.",
  vehicle_appear_database: "Araç, çalıntı araç veritabanında görünüyor",
  check_another_vehicle: "Başka bir aracı kontrol edin",
  get_more_information: "Daha fazla bilgi edinin",
  incidents_history: "Olay geçmişi",
  mileage_reading: "kilometre okuma",
  vehicle_inspections: "Araç muayeneleri",
  archival_photo: "Arşiv fotoğrafı",
  and_more: "ve dahası",
  check_now: "Şimdi kontrol et",
  all_rights_reserved: "Her hakkı saklıdır.",
  entered_vin_number: "Girilen VIN numarası",
  may_be_incorrect: "yanlış olabilir",
  correct: "Doğru",
  check: "Kontrol",
  incorrect: "Yanlış",
  check_again: "Tekrar kontrol et",
  entered_vin_is_incorrect: "Verilen VIN için veri yok veya numara geçersiz",
  reenter_your_vin: "Lütfen vin numaranızı tekrar girin",
  accept: "Kabul",
  regulations: "Yönetmelikler",
  back: "Geri",
  last_checked_vins: "Son kontrol edilen VIN'ler",
  enter_plate: "Araç tescil plakası",
  enter_country_code: "Ülke kodu",
  value_cannot_be_empty: "Değer boş olamaz",
  fast_and_reliable_stolen:
    "Hızlı ve Güvenilir Çalıntı Araç Veritabanı Kontrolü",
  welcome_our_website_desc:
    "<strong>Çalıntı araba veritabanlarını</strong> kontrol etmek için web sitemize hoş geldiniz. Arabanızın çalındığından şüpheleniyorsanız veya <strong>kendinizi çalıntı bir araç satın almaktan korumak</strong> istiyorsanız, hizmetimiz size yardımcı olabilir.",
  using_our_advanced_search_tools_desc:
    "Gelişmiş arama araçlarımızı kullanarak, sadece birkaç tıklamayla birden fazla veri tabanını <strong>çalıntı arabalar</strong> için kolayca kontrol edebilirsiniz. Sadece söz konusu arabanın VIN'ini veya plaka numarasını girin, <strong>kapsamlı veri tabanımızda</strong> aracın çalıntı olarak bildirilip bildirilmediğini araştıracağız.",
  our_service_is_fast_desc:
    "Hizmetimiz hızlı, güvenilir ve kullanımı kolaydır ve en doğru ve güncel bilgilere sahip olmanızı sağlamak için veritabanlarımızı düzenli olarak güncelleriz. Ayrıca, kullanıcı dostu arayüzümüzle, evinizin rahatlığında çalıntı arabaları hızlı ve kolay bir şekilde kontrol edebilirsiniz.",
  do_not_take_the_risk_of_buying_desc:
    "Bu nedenle, bilmeden çalıntı bir araba satın alma veya araba kullanma riskini almayın - kendinizi ve yatırımınızı korumak için çalıntı araba veri tabanı kontrolümüzü kullanın. Aramanıza şimdi başlayın! tamamen ücretsiz",
  help: "Yardım",
  check_if_vin_correct: "VIN numarasının doğruluğunu kontrol edin",
  check_valuation: "Ücretsiz bir araç değerlemesine göz atın",
  decode_vin_number: "VIN numarasının kodunu çöz",
  additional_links: "Ek bağlantılar",

  dangerous_models: "en güvenli modeller",
  most_popular: "En popüler modeller",
  most_expensive: "en pahalı modeller",
  according_stats_desc:
    "İstatistiklere göre, güvenlik sistemlerine sahip eski modeller hırsızlığa karşı en az hassas olan modellerdir.",
  most_frequently_desc:
    "En sık çalınan modeller, Volkswagen Golf veya Ford Focus gibi popüler marka ve modellerdir.",
  luxury_cars_such_as_desc:
    "Porsche, Lamborghini ve Ferrari gibi lüks otomobiller de hırsızlığa karşı en savunmasız olanlardır.",
  alarm_system: "Alarm Sistemleri",
  alarm_system_desc:
    "Birkaç düzeyde hırsızlık önleme korumasının varlığına dair bir alarm ve siren kurulumu.",
  steering_wheel_locks: "Direksiyon kilitleri",
  steering_wheel_locks_desc:
    "Arabanızı hırsızlığa karşı korumanın iyi bir yolu direksiyon kilitleri kullanmaktır.",
  hidding_keys: "anahtarları gizleme",
  hidding_keys_desc:
    "Bir hırsızı cezbedebilecekleri için anahtarlarınızı asla göz önünde bırakmayın.",
  parking_on_private_areas: "Özel arazide park etme",
  parking_on_private_areas_desc:
    "Özel arazide park yerlerini tercih ederek aracınızın güvende olduğundan emin olabilirsiniz.",
  how_to_prevent_theft: "Hırsızlık nasıl önlenir",
  secure_parking: "güvenli otopark",
  secure_parking_desc:
    "Arabanızı hırsızlığa karşı korumanın en iyi yollarından biri, onu gece izlenen ve aydınlatılan bir yere park etmektir. Kameraları, alarm sistemleri veya izlemek üzere görevlendirilen personeli olan park yerlerini arayın.",
  indoor_car_parking: "Kapalı otoparklar",
  indoor_car_parking_desc:
    "Şehir merkezindeki kapalı otoparkı kullanarak aracınızı kötü hava koşullarından ve hırsızlıktan koruyun.",
  limited_availability: "Sınırlı erişilebilirlik",
  limited_availability_desc:
    "Hırsızların işini zorlaştıran kısıtlı erişime ve şifrelere sahip bir park yeri seçin.",
  megaparks: "Megaparklar",
  megaparks_desc:
    "Mega otopark kullanıyorsanız, arabanızın girişe yakın bir yere park edildiğinden ve açıkça görülebildiğinden emin olun.",
  monitored_parking: "İzlenen otopark",
  monitored_parking_desc:
    "CCTV tarafından izlenen park yerlerini arayın ve kısa bir süre için terk etseniz bile arabanızı her zaman kilitleyin.",
  summary: "Özet",
  summary_desc:
    "Araba hırsızlığı dünya çapında ciddi bir sorundur. Yollardaki araç sayısındaki artış, suçluların artan faaliyetine vesile oluyor. Çalınan arabalar genellikle parça karşılığı satılır veya yurt dışına ihraç edilir, bu da sahiplerinin onları bulmasını zorlaştırır. Polis teşkilatları ve devlet kurumları, bu tür suçları önlemek ve bunlarla mücadele etmek için güvenlik teknolojisinin iyileştirilmesi ve suçluların kovuşturulmasında uluslararası işbirliği dahil olmak üzere çok sayıda önlem almaktadır. Sürücüler arasında eğitim ve farkındalık, araba hırsızlığını destekleyen durumları önlemek için de önemlidir.",
};
