export const hu = {
  free_vehicle_check: "Ingyenes jármű ellenőrzés",
  enter_vin_number: "Írja be a VIN-számot",
  must_be_17_characters: "17 karakter hosszúságúnak kell lennie",
  check_vehicle: "Ellenőrizze a járművet",
  buying_used_car_check_vehicle_desc:
    "Használt autót vásárol? Ellenőrizze a járművet adatbázisunkban!",
  database_have_records_desc:
    "Adatbázisunkban több mint 140 000 lopott járműre vonatkozó rekordot tartunk nyilván. Az adatbázist számos intézmény, ellenőr és információcsere-rendszer használja az ellopott járművekről.",
  checking_vehicle: "Jármű ellenőrzése",
  proccessing_please_wait: "Feldolgozás – kérjük, várjon",
  please_wait_takes_moment: "Kérlek várj. Csak egy pillanatig tart.",
  vehicle_not_found: "A jármű nem található",
  vehicle_found: "Megtalált jármű",
  vehicle_does_not_appear_database:
    "A jármű nem jelenik meg a lopott adatbázisban.",
  vehicle_appear_database: "A jármű megjelenik a lopott járművek adatbázisában",
  check_another_vehicle: "Ellenőrizze egy másik járművet",
  check_vehicle_history: "Ellenőrizze a jármű előzményeit",
  get_more_information: "További információkért",
  incidents_history: "Az események története",
  mileage_reading: "Futásteljesítmény leolvasás",
  vehicle_inspections: "Gépjárművek átvizsgálása",
  archival_photo: "Archív fotó",
  and_more: "és több",
  check_now: "Ellenőrizd most",
  all_rights_reserved: "Minden jog fenntartva.",
  entered_vin_number: "A beírt VIN szám",
  may_be_incorrect: "Lehet, hogy helytelen",
  correct: "Helyes",
  check: "Jelölje be",
  incorrect: "Helytelen",
  check_again: "Ellenőrizd újra",
  entered_vin_is_incorrect:
    "A megadott VIN-hez nincs adat, vagy a szám érvénytelen",
  reenter_your_vin: "Kérjük, adja meg újra a vin számot",
  accept: "Elfogad",
  regulations: "Előírások",
  back: "Vissza",
  last_checked_vins: "Nemrég ellenőrzött VIN-ek",
  enter_plate: "Gépjármű rendszámtábla",
  enter_country_code: "Ország kód",
  value_cannot_be_empty: "Az érték nem lehet üres",
  fast_and_reliable_stolen:
    "Gyors és megbízható lopott autók adatbázis-ellenőrzése",
  welcome_our_website_desc:
    "Üdvözöljük weboldalunkon, ahol ellenőrizheti a <strong>lopott autók adatbázisait</strong> . Ha azt gyanítja, hogy autóját ellopták, vagy meg akarja <strong>védeni magát attól, hogy lopott járművet vásároljon</strong> , szervizünk segíthet.",
  using_our_advanced_search_tools_desc:
    "Speciális keresőeszközeink segítségével egyszerűen, néhány kattintással több adatbázisban is ellenőrizheti az <strong>ellopott autókat</strong> . Egyszerűen adja meg a kérdéses autó alvázszámát vagy rendszámát, és átkutatjuk <strong>kiterjedt adatbázisunkban</strong> , hogy kiderüljön, nem jelentették-e lopottnak.",
  our_service_is_fast_desc:
    "Szolgáltatásunk gyors, megbízható és könnyen használható, adatbázisainkat rendszeresen frissítjük, hogy Ön a legpontosabb és legfrissebb információkkal rendelkezzen. Ráadásul felhasználóbarát felületünkkel gyorsan és egyszerűen ellenőrizheti az ellopott autókat saját otthonából.",
  do_not_take_the_risk_of_buying_desc:
    "Tehát ne kockáztassa meg, hogy lopott autót vásárol, vagy anélkül vezet, hogy tudna róla – használja lopott autók adatbázisának ellenőrzését, hogy megvédje magát és befektetését. Kezdje el a keresést most! Ez teljesen ingyenes",
  help: "Segítség",
  check_if_vin_correct: "Ellenőrizze a VIN szám helyességét",
  check_valuation: "Tekintse meg az ingyenes járműértékelést",
  decode_vin_number: "VIN szám dekódolása",
  additional_links: "További linkek",
  dangerous_models: "A legbiztonságosabb modellek",
  most_popular: "A legnépszerűbb modellek",
  most_expensive: "A legdrágább modellek",
  according_stats_desc:
    "A statisztikák szerint a régebbi, biztonsági rendszerrel felszerelt modellek a legkevésbé érzékenyek a lopásra.",
  most_frequently_desc:
    "A leggyakrabban ellopott modellek olyan népszerű márkák és modellek, mint a Volkswagen Golf vagy a Ford Focus.",
  luxury_cars_such_as_desc:
    "Az olyan luxusautók, mint a Porsche, a Lamborghini és a Ferrari, szintén a leginkább kiszolgáltatottak a lopással szemben.",
  alarm_system: "Riasztórendszerek",
  alarm_system_desc:
    "Riasztó és sziréna beszerelése több szintű lopásgátló védelem meglétére.",
  steering_wheel_locks: "Kormányzárak",
  steering_wheel_locks_desc:
    "Egy jó módja annak, hogy megvédje autóját a lopástól, ha kormányzárat használ",
  hidding_keys: "A kulcsok elrejtése",
  hidding_keys_desc:
    "Soha ne hagyja a kulcsokat jól látható helyen, mert tolvajt csábíthat.",
  parking_on_private_areas: "Parkolás saját telken",
  parking_on_private_areas_desc:
    "Ha előnyben részesíti a magánterületen lévő parkolóhelyeket, biztos lehet abban, hogy járműve biztonságban van.",
  how_to_prevent_theft: "Hogyan lehet megakadályozni a lopást",
  secure_parking: "Biztonságos parkolás",
  secure_parking_desc:
    "Az egyik legjobb módja annak, hogy megvédje autóját a lopástól, ha éjszaka figyelt és kivilágított helyen parkolja le. Keressen olyan parkolóhelyeket, ahol kamerák, riasztórendszerek vagy megfigyelésre kihelyezett személyzet található.",
  indoor_car_parking: "Fedett parkolók",
  indoor_car_parking_desc:
    "Védje meg autóját az időjárástól és a lopástól a belvárosi fedett parkoló használatával.",
  limited_availability: "Korlátozott elérhetőség",
  limited_availability_desc:
    "Válasszon olyan parkolót, ahol korlátozott hozzáférés és jelszavak állnak rendelkezésre, megnehezítve a tolvajok dolgát.",
  megaparks: "Megaparkok",
  megaparks_desc:
    "Ha megaparkolót használ, ügyeljen arra, hogy autója a bejárat közelében parkoljon, és jól látható legyen.",
  monitored_parking: "Felügyelt parkoló",
  monitored_parking_desc:
    "Keressen olyan parkolóhelyeket, amelyeket CCTV figyel, és mindig zárja le autóját, még akkor is, ha rövid időre elhagyja.",
  summary: "Összegzés",
  summary_desc:
    "Az autólopás világszerte komoly probléma. Az utakon közlekedő járművek számának növekedése kedvez a bűnözők aktivitásának növekedésének. Az ellopott autókat gyakran alkatrésznek adják el, vagy külföldre exportálják, ami megnehezíti tulajdonosaik visszaszerzését. A rendõrségi szervezetek és kormányzati intézmények számos intézkedést tesznek az ilyen típusú bûnözés megelőzése és leküzdése érdekében, ideértve a biztonsági technológia fejlesztését és a nemzetközi együttműködést a bűnözők üldözése terén. A sofőrök oktatása és tudatosítása szintén fontos az autólopásnak kedvezõ helyzetek megelőzése érdekében",
};
