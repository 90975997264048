<template>
  <div>
    <div
      v-if="getMessage == 'internal_error'"
      class="alert alert-danger text-center"
      role="alert"
    >
      {{ t("internal_error") }}
    </div>
    <div class="container py-3">
      <Header />
      <main>
        <router-view></router-view>
      </main>
      <Footer />
    </div>

    <div v-if="getCookie" class="cookies w-100">
      <Cookies />
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useI18n } from "vue-i18n";
import Cookies from "./components/Cookies.vue";
import { useRouter } from "vue-router";
import axios from "axios";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Cookies,
  },
  setup() {
    const { cookies } = useCookies();
    const store = useStore();
    const { t, locale } = useI18n();
    const router = useRouter();

    router.beforeEach((to, from, next) => {
      // store.dispatch("CANCEL_PENDING_REQUESTS");
      next();
    });

    const getMessage = computed(() => {
      return store.state.message;
    });

    const getCookie = computed(() => {
      return store.state.showCookie;
    });

    axios.interceptors.request.use(
      function (config) {
        let source = axios.CancelToken.source();

        config.cancelToken = source.token;

        store.commit("ADD_CANCEL_TOKEN", source);

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    onMounted(() => {
      let cookieValue = cookies.get("stolenCars");

      if (cookieValue == null || cookieValue != "policy_accepted") {
        store.commit("updateShowCookie", true);
      }
    });

    return {
      cookies,
      t,
      locale,
      getCookie,
      getMessage,
    };
  },
};
</script>

<style>
* {
  overflow-x: hidden;
}
html,
body {
  height: 100%;
  min-width: 360px;
}
.bg-grey {
  background-color: #f1f1f1;
}

.cookies {
  position: absolute;
  bottom: 0;
  z-index: 99;
}

div,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-y: hidden;
}

@media screen and (max-width: 700px) {
  .cookies {
    overflow-y: scroll;
    height: 350px;
  }
}
</style>