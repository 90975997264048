export const partnerLink = (lang) => {
  const partnersId = partnerIds();
  const link = `https://www.automoli.com/${partnersId[lang].domain}/page/order-reports/partnerid:${partnersId[lang].id}`;
  return link;
};

const partnerIds = () => {
  return {
    bg: {
      id: "80000440",
      domain: "bg",
    },
    ar: {
      id: "80000440",
      domain: "en",
    },
    cs: {
      id: "80000440",
      domain: "cs",
    },
    da: {
      id: "80000440",
      domain: "da",
    },
    de: {
      id: "80000440",
      domain: "de",
    },
    el: {
      id: "80000440",
      domain: "en",
    },
    en: {
      id: "80000440",
      domain: "en",
    },
    es: {
      id: "80000440",
      domain: "es",
    },
    fr: {
      id: "80000440",
      domain: "fr",
    },
    hu: {
      id: "80000440",
      domain: "hu",
    },
    it: {
      id: "80000440",
      domain: "it",
    },
    lt: {
      id: "80000440",
      domain: "lt",
    },
    nl: {
      id: "80000440",
      domain: "nl",
    },
    no: {
      id: "80000440",
      domain: "no",
    },
    pl: {
      id: "80000440",
      domain: "pl",
    },
    pt: {
      id: "80000440",
      domain: "pt",
    },
    ro: {
      id: "80000440",
      domain: "ro",
    },
    ru: {
      id: "80000440",
      domain: "ru",
    },
    sk: {
      id: "80000440",
      domain: "sk",
    },
    sr: {
      id: "80000440",
      domain: "sr",
    },
    sv: {
      id: "80000440",
      domain: "sv",
    },
    tr: {
      id: "80000440",
      domain: "en",
    },
    uk: {
      id: "80000440",
      domain: "uk",
    },
  };
};

export const langToPlate = (lang) => {
  const langs = {
    bg: "BG",
    ar: "SA",
    cs: "CZ",
    da: "DK",
    de: "DE",
    el: "GR",
    en: "GB",
    es: "ES",
    fr: "FR",
    hu: "HU",
    it: "IT",
    lt: "LT",
    nl: "NL",
    no: "NO",
    pl: "PL",
    pt: "PT",
    ro: "RO",
    ru: "RU",
    sk: "SK",
    sr: "RS",
    sv: "SE",
    tr: "TR",
    uk: "UA",
  };
  return langs[lang] ?? lang;
}

export const countryCodesAvailable = {
  A: "AT",
  AUS: "AU",
  B: "BE",
  BG: "BG",
  BY: "BY",
  CDN: "CA",
  CH: "CH",
  CZ: "CZ",
  D: "DE",
  DK: "DK",
  E: "ES",
  EST: "EE",
  ET: "EG",
  F: "FR",
  FIN: "FI",
  GB: "GB",
  GR: "GR",
  H: "HU",
  HR: "HR",
  I: "IT",
  IRL: "IE",
  IS: "IS",
  L: "LU",
  LT: "LT",
  LV: "LV",
  MC: "MC",
  N: "NO",
  NL: "NL",
  P: "PT",
  PL: "PL",
  RO: "RO",
  RUS: "RU",
  S: "SE",
  SA: "SA",
  SK: "SK",
  SLO: "SI",
  SRB: "RS",
  TR: "TR",
  UA: "UA",
  USA: "US",
  ZA: "ZA"
};

export const defaultState = () => {
  return {
    result: {
      vin_alternatives_list: [],
      vin_correct: "",
      vin_orginal: "",
    },
    precheck: {
      odometers: 0,
      damages: 0,
      events: 0,
      photos: 0,
    },
    stolen: false,
    message: "empty",
    showCookie: false,
    checkedVin: "",
    lang: navigator.language,
    cancelTokens: [],
    precheckStatus: false,
    lastCheckedVins: [],
    isRobot: true,
  };
};
