export const it = {
  free_vehicle_check: "Controllo gratuito del veicolo",
  enter_vin_number: "Inserisci il numero VIN",
  must_be_17_characters: "Deve essere lungo 17 caratteri",
  check_vehicle: "Controllare il veicolo",
  buying_used_car_check_vehicle_desc:
    "Stai acquistando un'auto usata? Controlla il veicolo nel nostro database!",
  database_have_records_desc:
    "Nel nostro database abbiamo oltre 140.000 registrazioni di veicoli rubati. Il database è utilizzato da molte istituzioni, ispettori e sistemi di scambio di informazioni sui veicoli rubati.",
  checking_vehicle: "Controllo del veicolo",
  proccessing_please_wait: "Elaborazione - attendere prego",
  please_wait_takes_moment: "Attendere prego. Ci vuole solo un momento.",
  vehicle_not_found: "Veicolo non trovato",
  vehicle_found: "Veicolo trovato",
  vehicle_does_not_appear_database:
    "Il veicolo non compare nel database rubato.",
  vehicle_appear_database: "Il veicolo appare nel database del veicolo rubato",
  check_another_vehicle: "Controlla un altro veicolo",
  check_vehicle_history: "Controlla la cronologia del veicolo",
  get_more_information: "Ottieni maggiori informazioni",
  incidents_history: "Storia degli incidenti",
  mileage_reading: "Lettura del chilometraggio",
  vehicle_inspections: "Ispezioni del veicolo",
  archival_photo: "Foto d'archivio",
  and_more: "e altro ancora",
  check_now: "Controlla ora",
  all_rights_reserved: "Tutti i diritti riservati.",
  entered_vin_number: "Il numero VIN inserito",
  may_be_incorrect: "Potrebbe non essere corretto",
  correct: "Corretta",
  check: "Dai un'occhiata",
  incorrect: "Errato",
  check_again: "Controlla di nuovo",
  entered_vin_is_incorrect:
    "Non ci sono dati per il VIN specificato o il numero non è valido",
  reenter_your_vin: "Per favore reinserisci il tuo numero di vin",
  accept: "Accettare",
  regulations: "Regolamenti",
  back: "Di ritorno",
  last_checked_vins: "VIN controllati di recente",
  enter_plate: "Targa di immatricolazione del veicolo",
  enter_country_code: "Prefisso internazionale",
  value_cannot_be_empty: "Il valore non può essere vuoto",
  fast_and_reliable_stolen:
    "Controllo veloce e affidabile del database delle auto rubate",
  welcome_our_website_desc:
    "Benvenuti nel nostro sito Web per il controllo dei <strong>database delle auto rubate</strong> . Se sospetti che la tua auto sia stata rubata o vuoi <strong>proteggerti dall'acquisto di un veicolo rubato</strong> , il nostro servizio può aiutarti.",
  using_our_advanced_search_tools_desc:
    "Utilizzando i nostri strumenti di ricerca avanzati, puoi controllare facilmente più database per <strong>auto rubate</strong> in pochi clic. Basta inserire il VIN o il numero di targa dell'auto in questione e cercheremo nel nostro <strong>ampio database</strong> per vedere se è stato segnalato come rubato.",
  our_service_is_fast_desc:
    "Il nostro servizio è veloce, affidabile e facile da usare e aggiorniamo regolarmente i nostri database per garantire che tu disponga delle informazioni più accurate e aggiornate. Inoltre, con la nostra interfaccia user-friendly, puoi controllare rapidamente e facilmente le auto rubate comodamente da casa tua.",
  do_not_take_the_risk_of_buying_desc:
    "Quindi non correre il rischio di acquistare un'auto rubata o guidarne una senza saperlo: usa il nostro controllo del database delle auto rubate per proteggere te stesso e il tuo investimento. Inizia subito la tua ricerca! È totalmente gratuito",
  help: "Aiuto",
  check_if_vin_correct: "Verificare la correttezza del numero VIN",
  check_valuation: "Dai un'occhiata a una valutazione gratuita del veicolo",
  decode_vin_number: "Decodifica il numero VIN",
  additional_links: "Collegamenti aggiuntivi",
  dangerous_models: "I modelli più sicuri",
  most_popular: "I modelli più popolari",
  most_expensive: "I modelli più costosi",
  according_stats_desc:
    "Secondo le statistiche, i modelli più vecchi con sistemi di sicurezza sono i meno suscettibili al furto.",
  most_frequently_desc:
    "I modelli più frequentemente rubati sono marchi e modelli popolari, come Volkswagen Golf o Ford Focus.",
  luxury_cars_such_as_desc:
    "Le auto di lusso come Porsche, Lamborghini e Ferrari sono anche le più vulnerabili ai furti.",
  alarm_system: "Sistemi di allarme",
  alarm_system_desc:
    "Installazione di un allarme e una sirena della presenza di diversi livelli di protezione antifurto.",
  steering_wheel_locks: "Bloccasterzo",
  steering_wheel_locks_desc:
    "Un buon modo per proteggere la tua auto dai furti è usare i bloccasterzo",
  hidding_keys: "Nascondere le chiavi",
  hidding_keys_desc:
    "Non lasciare mai le chiavi in bella vista perché potrebbero tentare un ladro.",
  parking_on_private_areas: "Parcheggio su terreno privato",
  parking_on_private_areas_desc:
    "Preferendo posti auto su terreno privato, puoi stare certo che il tuo veicolo è al sicuro.",
  how_to_prevent_theft: "Come prevenire i furti",
  secure_parking: "Parcheggio custodito",
  secure_parking_desc:
    "Uno dei modi migliori per proteggere la tua auto dai furti è parcheggiarla in un luogo sorvegliato e illuminato di notte. Cerca parcheggi dotati di telecamere, sistemi di allarme o personale incaricato di monitorare.",
  indoor_car_parking: "Parcheggi coperti",
  indoor_car_parking_desc:
    "Proteggi la tua auto dalle intemperie e dai furti utilizzando il parcheggio coperto in centro città.",
  limited_availability: "Disponibilità limitata",
  limited_availability_desc:
    "Scegli un parcheggio con accesso limitato e password, rendendo difficile per i ladri.",
  megaparks: "Megaparchi",
  megaparks_desc:
    "Se utilizzi un mega parcheggio, assicurati che la tua auto sia parcheggiata vicino all'ingresso e ben visibile.",
  monitored_parking: "Parcheggio custodito",
  monitored_parking_desc:
    "Cerca parcheggi sorvegliati da telecamere a circuito chiuso e chiudi sempre a chiave la tua auto, anche se la lasci per un breve periodo.",
  summary: "Riepilogo",
  summary_desc:
    "Il furto d'auto è un problema serio in tutto il mondo. L'aumento del numero di veicoli sulle strade favorisce la crescente attività dei criminali. Le auto rubate sono spesso vendute per pezzi di ricambio o esportate all'estero, rendendo difficile per i loro proprietari recuperarle. Le organizzazioni di polizia e le istituzioni governative intraprendono numerose azioni per prevenire e combattere questo tipo di crimine, tra cui il miglioramento della tecnologia di sicurezza e la cooperazione internazionale nel perseguire i criminali. Anche l'educazione e la sensibilizzazione degli automobilisti sono importanti per prevenire situazioni che favoriscono il furto dell'auto",
};
