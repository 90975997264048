export const no = {
  free_vehicle_check: "Gratis bilsjekk",
  enter_vin_number: "Skriv inn VIN-nummer",
  must_be_17_characters: "Må bestå av 17 tegn",
  check_vehicle: "Sjekk kjøretøyet",
  buying_used_car_check_vehicle_desc:
    "Kjøper du bruktbil? Sjekk kjøretøy i vår database!",
  database_have_records_desc:
    "I vår database har vi over 140 000 registreringer av stjålne kjøretøy. Databasen brukes av mange institusjoner, kontrollører og utveksling av informasjonssystemer om stjålne kjøretøy.",
  checking_vehicle: "Kontrollerer kjøretøy",
  proccessing_please_wait: "Behandler - vennligst vent",
  please_wait_takes_moment: "Vennligst vent. Det tar bare et øyeblikk.",
  vehicle_not_found: "Kjøretøy ikke funnet",
  vehicle_found: "Kjøretøy funnet",
  vehicle_does_not_appear_database:
    "Kjøretøyet vises ikke i den stjålne databasen.",
  vehicle_appear_database: "Kjøretøyet vises i databasen for stjålne kjøretøy",
  check_another_vehicle: "Sjekk et annet kjøretøy",
  check_vehicle_history: "Sjekk kjøretøyhistorikk",
  get_more_information: "Få mer informasjon",
  incidents_history: "Hendelser historie",
  mileage_reading: "Kilometeravlesning",
  vehicle_inspections: "Kjøretøykontroller",
  archival_photo: "Arkivbilde",
  and_more: "og mer",
  check_now: "Sjekk nå",
  all_rights_reserved: "Alle rettigheter forbeholdt.",
  entered_vin_number: "Det angitte VIN-nummeret",
  may_be_incorrect: "Det kan være feil",
  correct: "Riktig",
  check: "Kryss av",
  incorrect: "stemmer ikke",
  check_again: "Sjekk igjen",
  entered_vin_is_incorrect:
    "Det er ingen data for det gitte VIN-nummeret, eller nummeret er ugyldig",
  reenter_your_vin: "Vennligst skriv inn vin-nummeret ditt på nytt",
  accept: "Aksepterer",
  regulations: "Forskrifter",
  back: "Tilbake",
  last_checked_vins: "Nylig sjekket VIN-er",
  enter_plate: "Kjøretøyets registreringsskilt",
  enter_country_code: "Landskode",
  value_cannot_be_empty: "Verdien kan ikke være tom",
  fast_and_reliable_stolen: "Rask og pålitelig sjekk av stjålet bildatabase",
  welcome_our_website_desc:
    "Velkommen til vår nettside for sjekking av <strong>stjålne bildatabaser</strong> . Hvis du mistenker at bilen din er stjålet, eller du ønsker å <strong>beskytte deg mot å kjøpe et stjålet kjøretøy</strong> , kan vår tjeneste hjelpe deg.",
  using_our_advanced_search_tools_desc:
    "Ved å bruke våre avanserte søkeverktøy kan du enkelt sjekke flere databaser for <strong>stjålne biler</strong> med bare noen få klikk. Bare skriv inn VIN- eller platenummeret til den aktuelle bilen, så søker vi gjennom vår <strong>omfattende database</strong> for å se om den er meldt som stjålet.",
  our_service_is_fast_desc:
    "Tjenesten vår er rask, pålitelig og enkel å bruke, og vi oppdaterer databasene våre med jevne mellomrom for å sikre at du har den mest nøyaktige og oppdaterte informasjonen. I tillegg, med vårt brukervennlige grensesnitt, kan du raskt og enkelt se etter stjålne biler fra komforten av ditt eget hjem.",
  do_not_take_the_risk_of_buying_desc:
    "Så ikke ta risikoen ved å kjøpe en stjålet bil eller kjøre en uten å vite det – bruk vår databasesjekk for stjålne biler for å beskytte deg selv og investeringen din. Start søket nå! Det er helt gratis",
  help: "Hjelp",
  check_if_vin_correct: "Kontroller riktigheten av VIN-nummeret",
  check_valuation: "Sjekk ut en gratis bilvurdering",
  decode_vin_number: "Dekode VIN-nummer",
  additional_links: "Ytterligere lenker",

  dangerous_models: "De sikreste modellene",
  most_popular: "De mest populære modellene",
  most_expensive: "De dyreste modellene",
  according_stats_desc:
    "Ifølge statistikk er eldre modeller med sikkerhetssystemer minst utsatt for tyveri.",
  most_frequently_desc:
    "De mest stjålne modellene er populære merker og modeller, som Volkswagen Golf eller Ford Focus.",
  luxury_cars_such_as_desc:
    "Luksusbiler som Porsche, Lamborghini og Ferrari er også de mest sårbare for tyveri.",
  alarm_system: "Alarmsystemer",
  alarm_system_desc:
    "Installasjon av en alarm og en sirene for tilstedeværelsen av flere nivåer av tyveribeskyttelse.",
  steering_wheel_locks: "Rattlåser",
  steering_wheel_locks_desc:
    "En god måte å beskytte bilen mot tyveri er å bruke rattlås",
  hidding_keys: "Skjuler nøklene",
  hidding_keys_desc:
    "La aldri nøklene dine være synlige, da de kan friste en tyv.",
  parking_on_private_areas: "Parkering på privat eiendom",
  parking_on_private_areas_desc:
    "Hvis du foretrekker parkeringsplasser på privat grunn, kan du være sikker på at kjøretøyet ditt er trygt.",
  how_to_prevent_theft: "Hvordan forhindre tyveri",
  secure_parking: "Sikker parkering",
  secure_parking_desc:
    "En av de beste måtene å beskytte bilen mot tyveri er å parkere den på et sted som er overvåket og opplyst om natten. Se etter parkeringsplasser som har kameraer, alarmsystemer eller ansatte plassert for å overvåke.",
  indoor_car_parking: "Overbygde parkeringsplasser",
  indoor_car_parking_desc:
    "Beskytt bilen din mot vær og tyveri ved å bruke den overbygde parkeringen i sentrum.",
  limited_availability: "Begrenset tilgjengelighet",
  limited_availability_desc:
    "Velg en parkeringsplass med begrenset tilgang og passord, noe som gjør det vanskelig for tyver.",
  megaparks: "Megaparker",
  megaparks_desc:
    "Hvis du bruker en megaparkering, sørg for at bilen din er parkert nær inngangen og er godt synlig.",
  monitored_parking: "Overvåket parkeringsplass",
  monitored_parking_desc:
    "Se etter parkeringsplasser som overvåkes av CCTV og lås alltid bilen din, selv om du forlater den for en kort stund.",
  summary: "Sammendrag",
  summary_desc:
    "Biltyveri er et alvorlig problem over hele verden. Økningen i antall kjøretøy på veiene bidrar til den økende aktiviteten til kriminelle. Stjålne biler selges ofte for deler eller eksporteres til utlandet, noe som gjør det vanskelig for eierne å få dem tilbake. Politiorganisasjoner og statlige institusjoner iverksetter en rekke tiltak for å forebygge og bekjempe denne typen kriminalitet, inkludert forbedring av sikkerhetsteknologi og internasjonalt samarbeid for å straffeforfølge kriminelle. Utdanning og bevissthet blant sjåfører er også viktig for å forhindre situasjoner som favoriserer biltyveri",
};
