<template>
  <div
    class="
      position-relative
      overflow-hidden
      p-3 p-md-5
      m-md-3
      text-center
      bg-grey
      rounded-3
      mb-4
    "
  >
    <div class="col-md-12 p-lg-7 mx-auto my-2 my-md-5">
      <h1 class="display-4 fw-normal">{{ t("checking_vehicle") }}</h1>
      <p class="lead fw-bold mt-4">{{ vinNumber || vin }}</p>
      <img
        src="../assets/img/progress.svg"
        :alt="t('please_wait_takes_moment')"
      />
      <p class="fw-normal">{{ t("please_wait_takes_moment") }}</p>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import sendReq from "@/composables/sendReq";
import checkResponse from "@/composables/checkResponse";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "Progress",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const vin = ref("");
    const valid = ref(true);

    const vinNumber = computed(() => {
      return store.state.checkedVin;
    });

    onMounted(async () => {
      store.commit("updateMessage", "empty");
      const checkedVin = vinNumber.value || null;

      if (checkedVin === null) {
        vin.value = router.currentRoute.value.params.vin;
        valid.value = router.currentRoute.value.params.valid;
        store.dispatch("setCheckedVin", vin.value);
        const { sendForm, response } = sendReq();

        const send = await sendForm(vin.value, valid.value, 'noRobot');

        if (send == true) {
          const { directionRouter } = checkResponse(response.value);
          const direction = directionRouter();

          if (direction.params.vin) {
            router.push({
              name: direction.name,
              params: {
                vin: vin.value,
              },
            });
          } else {
            router.push({
              name: direction.name,
            });
          }
        } else {
          if (send == "REQUEST_CANCELED") {
            return;
          } else {
            store.commit("updateMessage", "internal_error");
            router.push({
              name: "home",
            });
          }
        }
      }
    });

    return {
      vinNumber,
      t,
      vin,
    };
  },
};
</script>