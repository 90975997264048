export const sv = {
  free_vehicle_check: "Gratis fordonskontroll",
  enter_vin_number: "Ange VIN-nummer",
  must_be_17_characters: "Måste vara 17 tecken långt",
  check_vehicle: "Kontrollera fordonet",
  buying_used_car_check_vehicle_desc:
    "Köper du en begagnad bil? Kolla fordon i vår databas!",
  database_have_records_desc:
    "I vår databas har vi över 140 000 register över stulna fordon. Databasen används av många institutioner, inspektörer och utbyte av informationssystem om stulna fordon.",
  checking_vehicle: "Kontrollerar fordon",
  proccessing_please_wait: "Bearbetar - vänta",
  please_wait_takes_moment: "Vänta. Det tar bara ett ögonblick.",
  vehicle_not_found: "Fordonet hittades inte",
  vehicle_found: "Fordon hittat",
  vehicle_does_not_appear_database:
    "Fordonet förekommer inte i den stulna databasen.",
  vehicle_appear_database: "Fordonet visas i databasen för stulna fordon",
  check_another_vehicle: "Kontrollera ett annat fordon",
  check_vehicle_history: "Kontrollera fordonshistorik",
  get_more_information: "Skaffa mer information",
  incidents_history: "Incidents historia",
  mileage_reading: "Kilometeravläsning",
  vehicle_inspections: "Fordonsbesiktningar",
  archival_photo: "Arkivfoto",
  and_more: "och mer",
  check_now: "Kolla nu",
  all_rights_reserved: "Alla rättigheter förbehållna.",
  entered_vin_number: "Det angivna VIN-numret",
  may_be_incorrect: "Det kan vara felaktigt",
  correct: "Korrekt",
  check: "Kolla upp",
  incorrect: "Felaktig",
  check_again: "Kolla igen",
  entered_vin_is_incorrect:
    "Det finns inga data för det givna VIN-numret eller så är numret ogiltigt",
  reenter_your_vin: "Vänligen ange ditt vinnummer igen",
  accept: "Acceptera",
  regulations: "förordningar",
  back: "Tillbaka",
  last_checked_vins: "Nyligen kontrollerade VIN",
  enter_plate: "Fordons registreringsskylt",
  enter_country_code: "Landskod",
  value_cannot_be_empty: "Värdet får inte vara tomt",
  fast_and_reliable_stolen: "Snabb och pålitlig kontroll av stulen bildatabas",
  welcome_our_website_desc:
    "Välkommen till vår hemsida för kontroll av <strong>stulna bilars databaser</strong> . Om du misstänker att din bil har blivit stulen, eller om du vill <strong>skydda dig från att köpa ett stulet fordon</strong> , kan vår service hjälpa dig.",
  using_our_advanced_search_tools_desc:
    "Med hjälp av våra avancerade sökverktyg kan du enkelt kontrollera flera databaser efter <strong>stulna bilar</strong> med bara några klick. Ange bara VIN eller skyltnummer för bilen i fråga, så söker vi igenom vår <strong>omfattande databas</strong> för att se om den har rapporterats som stulen.",
  our_service_is_fast_desc:
    "Vår tjänst är snabb, pålitlig och lätt att använda, och vi uppdaterar våra databaser regelbundet för att säkerställa att du har den mest korrekta och aktuella informationen. Plus, med vårt användarvänliga gränssnitt kan du snabbt och enkelt leta efter stulna bilar från bekvämligheten av ditt eget hem.",
  do_not_take_the_risk_of_buying_desc:
    "Så ta inte risken att köpa en stulen bil eller köra en utan att veta - använd vår databaskontroll för stulna bilar för att skydda dig själv och din investering. Börja din sökning nu! Det är helt gratis",
  help: "Hjälp",
  check_if_vin_correct: "Kontrollera att VIN-numret är korrekt",
  check_valuation: "Kolla in en kostnadsfri fordonsvärdering",
  decode_vin_number: "Avkoda VIN-nummer",
  additional_links: "Ytterligare länkar",

  dangerous_models: "De säkraste modellerna",
  most_popular: "De mest populära modellerna",
  most_expensive: "De dyraste modellerna",
  according_stats_desc:
    "Enligt statistiken är äldre modeller med säkerhetssystem minst mottagliga för stöld.",
  most_frequently_desc:
    "De mest stulna modellerna är populära märken och modeller, som Volkswagen Golf eller Ford Focus.",
  luxury_cars_such_as_desc:
    "Lyxbilar som Porsche, Lamborghini och Ferrari är också de mest utsatta för stöld.",
  alarm_system: "Larmsystem",
  alarm_system_desc:
    "Installation av ett larm och en siren för närvaron av flera nivåer av stöldskydd.",
  steering_wheel_locks: "Rattlås",
  steering_wheel_locks_desc:
    "Ett bra sätt att skydda din bil från stöld är att använda rattlås",
  hidding_keys: "Döljer nycklarna",
  hidding_keys_desc:
    "Lämna aldrig dina nycklar inom synhåll eftersom de kan fresta en tjuv.",
  parking_on_private_areas: "Parkering på privat tomt",
  parking_on_private_areas_desc:
    "Om du föredrar parkeringsplatser på privat mark kan du vara säker på att ditt fordon är säkert.",
  how_to_prevent_theft: "Hur man förhindrar stöld",
  secure_parking: "Säker parkering",
  secure_parking_desc:
    "Ett av de bästa sätten att skydda din bil från stöld är att parkera den på en plats som är övervakad och upplyst på natten. Leta efter parkeringsplatser som har kameror, larmsystem eller personal placerade för att övervaka.",
  indoor_car_parking: "Täckta parkeringar",
  indoor_car_parking_desc:
    "Skydda din bil från väder och stöld genom att använda den täckta parkeringen i stadens centrum.",
  limited_availability: "Begränsad tillgänglighet",
  limited_availability_desc:
    "Välj en parkeringsplats med begränsad åtkomst och lösenord, vilket gör det svårt för tjuvar.",
  megaparks: "Megaparker",
  megaparks_desc:
    "Om du använder en megaparkering, se till att din bil är parkerad nära entrén och är väl synlig.",
  monitored_parking: "Övervakad parkering",
  monitored_parking_desc:
    "Leta efter parkeringsplatser som övervakas av CCTV och lås alltid din bil, även om du lämnar den en kort stund.",
  summary: "Sammanfattning",
  summary_desc:
    "Bilstölder är ett allvarligt problem över hela världen. Ökningen av antalet fordon på vägarna bidrar till den ökande aktiviteten för kriminella. Stulna bilar säljs ofta som delar eller exporteras utomlands, vilket gör det svårt för deras ägare att få tillbaka dem. Polisorganisationer och statliga institutioner vidtar många åtgärder för att förebygga och bekämpa denna typ av brottslighet, inklusive förbättrad säkerhetsteknik och internationellt samarbete för att lagföra brottslingar. Utbildning och medvetenhet bland förare är också viktigt för att förhindra situationer som gynnar bilstölder",
};
