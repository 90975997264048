export const uk = {
  free_vehicle_check: "Безкоштовна перевірка транспортного засобу",
  enter_vin_number: "Введіть VIN-номер",
  must_be_17_characters: "Має містити 17 символів",
  check_vehicle: "Перевірити транспортний засіб",
  buying_used_car_check_vehicle_desc:
    "Ви купуєте вживане авто? Перевірте автомобіль в нашій базі!",
  database_have_records_desc:
    "У нашій базі даних ми маємо понад 140 000 записів про викрадені транспортні засоби. Базою даних користуються багато установ, інспектори та обмінні інформаційними системами про викрадені транспортні засоби.",
  checking_vehicle: "Перевірка транспортного засобу",
  proccessing_please_wait: "Обробка - зачекайте",
  please_wait_takes_moment: "Будь ласка, зачекайте. Це займає лише мить.",
  vehicle_not_found: "Транспортний засіб не знайдено",
  vehicle_found: "Транспортний засіб знайдено",
  vehicle_does_not_appear_database:
    "Транспортний засіб не відображається в базі викрадених.",
  vehicle_appear_database:
    "Транспортний засіб з’являється в базі даних про викрадені транспортні засоби",
  check_another_vehicle: "Перевірте інший автомобіль",
  check_vehicle_history: "Перевірте історію автомобіля",
  get_more_information: "Отримайте більше інформації",
  incidents_history: "Історія інцидентів",
  mileage_reading: "Читання пробігу",
  vehicle_inspections: "Огляди транспортних засобів",
  archival_photo: "Архівне фото",
  and_more: "і більше",
  check_now: "Перевір зараз",
  all_rights_reserved: "Всі права захищені.",
  entered_vin_number: "Введений VIN-номер",
  may_be_incorrect: "Це може бути неправильно",
  correct: "Правильно",
  check: "Перевірте",
  incorrect: "Неправильно",
  check_again: "Перевірте ще раз",
  entered_vin_is_incorrect: "Введений номер vin неправильний",
  reenter_your_vin: "Будь ласка, введіть свій vin номер ще раз",
  accept: "Прийняти",
  regulations: "Положення",
  back: "Назад",
  last_checked_vins: "Нещодавно перевірені VIN",
  enter_plate: "Номерний знак транспортного засобу",
  enter_country_code: "Код країни",
  value_cannot_be_empty: "Значення не може бути порожнім",
  fast_and_reliable_stolen:
    "Швидка та надійна перевірка бази даних про викрадені автомобілі",
  welcome_our_website_desc:
    "Ласкаво просимо на наш сайт для перевірки <strong>баз даних викрадених автомобілів</strong> . Якщо ви підозрюєте, що вашу машину викрали, або хочете <strong>убезпечити себе від придбання краденої машини</strong> , наша служба допоможе вам.",
  using_our_advanced_search_tools_desc:
    "Використовуючи наші розширені інструменти пошуку, ви можете легко перевірити численні бази даних на <strong>викрадені автомобілі</strong> всього за кілька кліків. Просто введіть VIN-код або номерний знак відповідного автомобіля, і ми здійснимо пошук у нашій <strong>великій базі даних</strong> , щоб дізнатися, чи було повідомлено про викрадення.",
  our_service_is_fast_desc:
    "Наш сервіс є швидким, надійним і простим у використанні, і ми регулярно оновлюємо наші бази даних, щоб забезпечити вам найточнішу та найновішу інформацію. Крім того, за допомогою нашого зручного інтерфейсу ви можете швидко та легко перевіряти викрадені автомобілі, не виходячи з дому.",
  do_not_take_the_risk_of_buying_desc:
    "Тож не ризикуйте, купуючи викрадений автомобіль або керуючи ним, не знаючи про це – скористайтеся нашою перевіркою бази даних про викрадені автомобілі, щоб захистити себе та свої інвестиції. Почніть пошук зараз! Це абсолютно безкоштовно",
  help: "Довідка",
  check_if_vin_correct: "Перевірте правильність номера VIN",
  check_valuation: "Перегляньте безкоштовну оцінку автомобіля",
  decode_vin_number: "Розшифруйте номер VIN",
  additional_links: "Додаткові посилання",

  dangerous_models: "Найбезпечніші моделі",
  most_popular: "Найпопулярніші моделі",
  most_expensive: "Найдорожчі моделі",
  according_stats_desc:
    "Згідно зі статистикою, старі моделі з системами безпеки найменш схильні до крадіжки.",
  most_frequently_desc:
    "Найчастіше крадуть моделі популярних марок і моделей, таких як Volkswagen Golf або Ford Focus.",
  luxury_cars_such_as_desc:
    "Розкішні автомобілі, такі як Porsche, Lamborghini і Ferrari, також є найбільш вразливими до крадіжок.",
  alarm_system: "Системи сигналізації",
  alarm_system_desc:
    "Встановлення сигналізації та сирени наявності кількох рівнів протиугінного захисту.",
  steering_wheel_locks: "Замки керма",
  steering_wheel_locks_desc:
    "Хорошим способом захисту автомобіля від викрадення є використання замків керма",
  hidding_keys: "Ховання ключів",
  hidding_keys_desc:
    "Ніколи не залишайте свої ключі на виду, оскільки вони можуть спокусити злодія.",
  parking_on_private_areas: "Парковка на приватній території",
  parking_on_private_areas_desc:
    "Віддаючи перевагу парковочним місцям на приватній території, ви можете бути впевнені, що ваш автомобіль у безпеці.",
  how_to_prevent_theft: "Як запобігти крадіжці",
  secure_parking: "Охороняється паркінг",
  secure_parking_desc:
    "Один із найкращих способів захистити свій автомобіль від викрадення – припаркувати його в освітленому та контрольованому місці вночі. Шукайте паркувальні місця з камерами, системами сигналізації або персоналом для спостереження.",
  indoor_car_parking: "Криті автостоянки",
  indoor_car_parking_desc:
    "Захистіть свій автомобіль від негоди та крадіжок, скориставшись критою парковкою в центрі міста.",
  limited_availability: "Обмежена доступність",
  limited_availability_desc:
    "Виберіть стоянку з обмеженим доступом і паролями, що ускладнить роботу злодіїв.",
  megaparks: "Мегапарки",
  megaparks_desc:
    "Якщо ви користуєтеся великою автостоянкою, переконайтеся, що ваша машина припаркована біля входу та її добре видно.",
  monitored_parking: "Автостоянка під наглядом",
  monitored_parking_desc:
    "Шукайте паркувальні місця, які контролюються системами відеоспостереження, і завжди замикайте свій автомобіль, навіть якщо ви залишаєте його на короткий час.",
  summary: "Резюме",
  summary_desc:
    "Крадіжки автомобілів є серйозною проблемою в усьому світі. Збільшення кількості транспортних засобів на дорогах сприяє підвищенню активності злочинців. Викрадені автомобілі часто продають на запчастини або вивозять за кордон, тому їхнім власникам важко їх повернути. Поліцейські організації та державні установи вживають численних заходів для запобігання та боротьби з цим видом злочинності, включаючи вдосконалення технологій безпеки та міжнародне співробітництво у переслідуванні злочинців. Навчання та обізнаність водіїв також важливі для запобігання ситуаціям, які сприяють крадіжці автомобіля",
};
