import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/store";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { globalCookiesConfig } from "vue3-cookies";
import VueGtag from "vue-gtag-next";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes/Routes";
import { i18n } from './utils/langs';

globalCookiesConfig({
  expireTimes: "30d",
  path: "/",
  domain: "https://www.stolencars.eu",
  secure: true,
  sameSite: "None",
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App)
  .use(VueGtag, {
    property: {
      id: "G-MY52HEHF32",
      params: {
        send_page_view: true,
        linker: {
          domain: ["stolencars.eu"],
        },
      },
    },
    useDebugger: false,
  })
  .use(store)
  .use(VueReCaptcha, { siteKey: "6Ld4zzMeAAAAAJhohjEHQT8EDAvwiSPL5fO3xA0B" })
  //.use(VueReCaptcha, { siteKey: "6LeqcqMgAAAAAJu64oca9NncbxHmOlJo4Pq95m0v"})
  .use(i18n)
  .use(router)
  .mount("#app");
