export const ro = {
  free_vehicle_check: "Verificare gratuită a vehiculului",
  enter_vin_number: "Introduceți numărul VIN",
  must_be_17_characters: "Trebuie să aibă 17 caractere",
  check_vehicle: "Verificați vehiculul",
  buying_used_car_check_vehicle_desc:
    "Cumperi o mașină folosită? Verificați vehiculul în baza noastră de date!",
  database_have_records_desc:
    "În baza noastră de date avem peste 140.000 de înregistrări ale vehiculelor furate. Baza de date este utilizată de multe instituții, inspectori și sisteme de schimb de informații despre vehiculele furate.",
  checking_vehicle: "Verificarea vehiculului",
  proccessing_please_wait: "Procesare - vă rugăm să așteptați",
  please_wait_takes_moment: "Va rugam asteptati. Durează doar un moment.",
  vehicle_not_found: "Vehiculul nu a fost găsit",
  vehicle_found: "Vehicul găsit",
  vehicle_does_not_appear_database:
    "Vehiculul nu apare în baza de date furată.",
  vehicle_appear_database:
    "Vehiculul apare în baza de date a vehiculelor furate",
  check_another_vehicle: "Verificați alt vehicul",
  check_vehicle_history: "Verificați istoricul vehiculului",
  get_more_information: "Obțineți mai multe informații",
  incidents_history: "Istoricul incidentelor",
  mileage_reading: "Citirea kilometrajului",
  vehicle_inspections: "Inspecții de vehicule",
  archival_photo: "Fotografie de arhivă",
  and_more: "și altele",
  check_now: "Verifica acum",
  all_rights_reserved: "Toate drepturile rezervate.",
  entered_vin_number: "Numărul VIN introdus",
  may_be_incorrect: "Poate fi incorect",
  correct: "Corect",
  check: "Verifica",
  incorrect: "Incorect",
  check_again: "Verificați din nou",
  entered_vin_is_incorrect:
    "Nu există date pentru VIN-ul dat sau numărul este invalid",
  reenter_your_vin: "Vă rugăm să reintroduceți numărul dvs. de vin",
  accept: "Accept",
  regulations: "Reguli",
  back: "Înapoi",
  last_checked_vins: "VIN-uri verificate recent",
  enter_plate: "Plăcuța de înmatriculare a vehiculului",
  enter_country_code: "Codul tarii",
  value_cannot_be_empty: "Valoarea nu poate fi goală",
  fast_and_reliable_stolen:
    "Verificare rapidă și de încredere a bazei de date pentru mașini furate",
  welcome_our_website_desc:
    "Bine ați venit pe site-ul nostru pentru verificarea <strong>bazelor de date cu mașini furate</strong> . Dacă bănuiți că mașina dvs. a fost furată sau doriți să <strong>vă protejați de achiziționarea unui vehicul furat</strong> , serviciul nostru vă poate ajuta.",
  using_our_advanced_search_tools_desc:
    "Folosind instrumentele noastre avansate de căutare, puteți verifica cu ușurință mai multe baze de date pentru <strong>mașini furate</strong> în doar câteva clicuri. Pur și simplu introduceți VIN-ul sau numărul de plăcuță al mașinii în cauză și vom căuta în baza noastră de <strong>date extinsă</strong> pentru a vedea dacă a fost raportată ca furată.",
  our_service_is_fast_desc:
    "Serviciul nostru este rapid, fiabil și ușor de utilizat, iar bazele noastre de date sunt actualizate în mod regulat pentru a ne asigura că aveți cele mai exacte și actualizate informații. În plus, cu interfața noastră ușor de utilizat, puteți verifica rapid și ușor dacă există mașini furate din confortul propriei case.",
  do_not_take_the_risk_of_buying_desc:
    "Așa că nu vă asumați riscul de a cumpăra o mașină furată sau de a conduce una fără să știți - utilizați verificarea bazei de date a mașinilor furate pentru a vă proteja pe dumneavoastră și investiția. Începeți căutarea acum! Este complet gratuit",
  help: "Ajutor",
  check_if_vin_correct: "Verificați corectitudinea numărului VIN",
  check_valuation: "Consultați o evaluare gratuită a vehiculului",
  decode_vin_number: "Decodați numărul VIN",
  additional_links: "Link-uri suplimentare",

  dangerous_models: "Cele mai sigure modele",
  most_popular: "Cele mai populare modele",
  most_expensive: "Cele mai scumpe modele",
  according_stats_desc:
    "Potrivit statisticilor, modelele mai vechi cu sisteme de securitate sunt cele mai puțin susceptibile la furt.",
  most_frequently_desc:
    "Modelele furate cel mai frecvent sunt mărci și modele populare, precum Volkswagen Golf sau Ford Focus.",
  luxury_cars_such_as_desc:
    "Mașinile de lux precum Porsche, Lamborghini și Ferrari sunt, de asemenea, cele mai vulnerabile la furt.",
  alarm_system: "Sisteme de alarma",
  alarm_system_desc:
    "Instalarea unei alarme și a unei sirene de prezență a mai multor niveluri de protecție antifurt.",
  steering_wheel_locks: "Blocarea direcției",
  steering_wheel_locks_desc:
    "O modalitate bună de a vă proteja mașina de furt este să utilizați blocarea direcției",
  hidding_keys: "Ascunderea cheilor",
  hidding_keys_desc:
    "Nu lăsa niciodată cheile la vedere, deoarece pot ispiti un hoț.",
  parking_on_private_areas: "Parcare pe teren privat",
  parking_on_private_areas_desc:
    "Preferând locuri de parcare pe teren privat, poți fi sigur că vehiculul tău este în siguranță.",
  how_to_prevent_theft: "Cum să previi furtul",
  secure_parking: "Parcare securizată",
  secure_parking_desc:
    "Una dintre cele mai bune modalități de a vă proteja mașina de furt este să o parcați într-un loc care este monitorizat și iluminat noaptea. Căutați locuri de parcare care au camere, sisteme de alarmă sau personal postat pentru monitorizare.",
  indoor_car_parking: "Parcari acoperite",
  indoor_car_parking_desc:
    "Protejați-vă mașina de intemperii și furt folosind parcarea acoperită din centrul orașului.",
  limited_availability: "Valabilitate limitata",
  limited_availability_desc:
    "Alegeți o parcare cu acces și parole restricționate, ceea ce îngreunează hoții.",
  megaparks: "Megaparcuri",
  megaparks_desc:
    "Dacă utilizați o mega parcare, asigurați-vă că mașina dvs. este parcată aproape de intrare și este clar vizibilă.",
  monitored_parking: "Parcare supravegheată",
  monitored_parking_desc:
    "Căutați locuri de parcare care sunt monitorizate de CCTV și încuiați întotdeauna mașina, chiar dacă o lăsați pentru o perioadă scurtă de timp.",
  summary: "rezumat",
  summary_desc:
    "Furtul de mașini este o problemă serioasă la nivel mondial. Creșterea numărului de vehicule pe șosele este propice creșterii activității infractorilor. Mașinile furate sunt adesea vândute pentru piese sau exportate în străinătate, ceea ce îngreunează recuperarea acestora de către proprietari. Organizațiile de poliție și instituțiile guvernamentale întreprind numeroase acțiuni pentru prevenirea și combaterea acestui tip de infracțiuni, inclusiv îmbunătățirea tehnologiei de securitate și cooperarea internațională în urmărirea penală a criminalilor. Educația și conștientizarea șoferilor sunt, de asemenea, importante pentru a preveni situațiile care favorizează furtul de mașini",
};
