<template>
  <button @click="$emit('check')" :class="`${classes} ${!customColor ? 'btn-blue' : ''} btn`" type="submit">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    classes: String,
    customColor: {
      type: Boolean,
      default: false
    }
  },
  setup() {},
};
</script>

<style scoped>
.btn-blue {
  background-color: #0096ff;
  color: white;
}
</style>