<template>
  <div class="container">
    <router-link to="/">{{ t("back") }}</router-link>
    <h4 class="mt-4">{{ t("admission") }}</h4>
    <ul>
      <li
        v-html="t('regulations_admission', { email: 'info@cardataexperts.com' })"
      ></li>
    </ul>

    <h4>{{ t("definition") }}</h4>
    <ul>
      <li v-html="t('regulations_definition')"></li>
    </ul>

    <h4>{{ t("license_use_page") }}</h4>
    <ul>
      <li v-html="t('regulations_license_desc')"></li>
    </ul>

    <h4>{{ t("use_page") }}</h4>
    <ul>
      <li v-html="t('regulations_use_page')"></li>
    </ul>

    <h4>{{ t("limited_access") }}</h4>
    <ul>
      <li v-html="t('regulations_limited_access')"></li>
    </ul>

    <h4>{{ t("user_posted_content") }}</h4>
    <ul>
      <li v-html="t('regulations_user_posted')"></li>
    </ul>

    <h4>{{ t("placing_vehicle_data_in_db") }}</h4>
    <ul>
      <li v-html="t('regulations_placing_data_in_db')"></li>
    </ul>
    <h4>{{ t("limited_and_warranties_complaints") }}</h4>
    <ul>
      <li v-html="t('regulations_limited_and_warranties_complaints')"></li>
    </ul>

    <h4>{{ t("disclaimer_of_liability") }}</h4>
    <ul>
      <li v-html="t('regulations_liability')"></li>
    </ul>

    <h4>{{ t("relief_from_obligations") }}</h4>
    <ul>
      <li v-html="t('regulations_relief')"></li>
    </ul>

    <h4>{{ t("violations_of_regulations") }}</h4>
    <ul>
      <li v-html="t('reulations_violations')"></li>
    </ul>

    <h4>{{ t("changing_of_provisions_regulations") }}</h4>
    <ul>
      <li v-html="t('regulations_changing_provisions_regulations')"></li>
    </ul>

    <h4>{{ t("transfer") }}</h4>
    <ul>
      <li v-html="t('regulations_transfer')"></li>
    </ul>

    <h4>{{ t("severability_clause") }}</h4>
    <ul>
      <li v-html="t('regulations_severability_clause')"></li>
    </ul>

    <h4>{{ t("exclusion_third_parts") }}</h4>
    <ul>
      <li v-html="t('regulations_exclusion_parts')"></li>
    </ul>

    <h4>{{ t("scope_of_contract") }}</h4>
    <ul>
      <li v-html="t('regulations_scope_contract')"></li>
    </ul>

    <h4>{{ t("governing_law_and_jurisdiction") }}</h4>
    <ul>
      <li v-html="t('regulations_governing_law')"></li>
    </ul>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
import { useStore } from 'vuex';

export default {
  name: "Regulations",
  setup() {
    const { t } = useI18n();
    const store = useStore();

    onMounted(() => {
      return store.commit("updateMessage", "empty");
    });

    return {
      t,
    };
  },
};
</script>

<style scoped>
ul {
  padding: 0;
}
li {
  font-size: 14px;
  /* margin-right: 30px; */
}

a:hover {
  text-decoration: underline;
}
</style>