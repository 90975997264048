export const nl = {
  free_vehicle_check: "Gratis voertuigcontrole",
  enter_vin_number: "VIN-nummer invoeren",
  must_be_17_characters: "Moet 17 tekens lang zijn",
  check_vehicle: "Controleer voertuig",
  buying_used_car_check_vehicle_desc:
    "U koopt een gebruikte auto? Controleer voertuig in onze database!",
  database_have_records_desc:
    "In onze database hebben we meer dan 140.000 records van gestolen voertuigen. De database wordt gebruikt door veel instellingen, inspecteurs en uitwisseling van informatiesystemen over gestolen voertuigen.",
  checking_vehicle: "Voertuig controleren",
  proccessing_please_wait: "Aan het verwerken ... Even wachten, a.u.b",
  please_wait_takes_moment: "Wacht alsjeblieft. Het duurt maar een moment.",
  vehicle_not_found: "Voertuig niet gevonden",
  vehicle_found: "Voertuig gevonden",
  vehicle_does_not_appear_database:
    "Het voertuig komt niet voor in de gestolen database.",
  vehicle_appear_database:
    "Het voertuig verschijnt in de gestolen voertuigdatabase",
  check_another_vehicle: "Controleer een ander voertuig",
  check_vehicle_history: "Voertuiggeschiedenis controleren",
  get_more_information: "Krijg meer informatie",
  incidents_history: "Incidenten geschiedenis",
  mileage_reading: "Kilometerstand lezen",
  vehicle_inspections: "Voertuigkeuringen",
  archival_photo: "Archieffoto",
  and_more: "en meer",
  check_now: "Check nu",
  all_rights_reserved: "Alle rechten voorbehouden.",
  entered_vin_number: "Het ingevoerde VIN-nummer",
  may_be_incorrect: "Het kan onjuist zijn",
  correct: "Correct",
  check: "Rekening",
  incorrect: "Niet correct",
  check_again: "Controleer nog eens",
  entered_vin_is_incorrect:
    "Er zijn geen gegevens voor het opgegeven VIN of het nummer is ongeldig",
  reenter_your_vin: "Voer uw vinnummer opnieuw in",
  accept: "Aanvaarden",
  regulations: "Regelgeving",
  back: "Rug",
  last_checked_vins: "Onlangs gecontroleerde VIN's",
  enter_plate: "kentekenplaat",
  enter_country_code: "Landcode",
  value_cannot_be_empty: "De waarde mag niet leeg zijn",
  fast_and_reliable_stolen:
    "Snelle en betrouwbare databasecontrole voor gestolen auto's",
  welcome_our_website_desc:
    "Welkom op onze website voor het controleren <strong>van databases met gestolen auto's</strong> . Als u vermoedt dat uw auto is gestolen, of als u <strong>zich wilt beschermen tegen de aankoop van een gestolen voertuig</strong> , kan onze service u helpen.",
  using_our_advanced_search_tools_desc:
    "Met behulp van onze geavanceerde zoekhulpmiddelen kunt u eenvoudig met slechts een paar klikken meerdere databases voor <strong>gestolen auto's</strong> doorzoeken. Voer gewoon het VIN- of plaatnummer van de auto in kwestie in en we doorzoeken onze <strong>uitgebreide database</strong> om te zien of deze als gestolen is opgegeven.",
  our_service_is_fast_desc:
    "Onze service is snel, betrouwbaar en gebruiksvriendelijk en we werken onze databases regelmatig bij om ervoor te zorgen dat u over de meest nauwkeurige en actuele informatie beschikt. Bovendien kunt u met onze gebruiksvriendelijke interface snel en eenvoudig controleren op gestolen auto's vanuit het comfort van uw eigen huis.",
  do_not_take_the_risk_of_buying_desc:
    "Neem dus niet het risico een gestolen auto te kopen of erin te rijden zonder het te weten - gebruik onze databasecontrole voor gestolen auto's om uzelf en uw investering te beschermen. Begin nu met zoeken! Het is helemaal gratis",
  help: "Helpen",
  check_if_vin_correct: "Controleer de juistheid van het VIN-nummer",
  check_valuation: "Bekijk een gratis waardebepaling van uw voertuig",
  decode_vin_number: "VIN-nummer decoderen",
  additional_links: "Extra koppelingen",

  dangerous_models: "De veiligste modellen",
  most_popular: "De meest populaire modellen",
  most_expensive: "De duurste modellen",
  according_stats_desc:
    "Volgens de statistieken zijn oudere modellen met beveiligingssystemen het minst diefstalgevoelig.",
  most_frequently_desc:
    "De meest gestolen modellen zijn populaire merken en modellen, zoals Volkswagen Golf of Ford Focus.",
  luxury_cars_such_as_desc:
    "Luxe auto's zoals Porsche, Lamborghini en Ferrari zijn ook het meest kwetsbaar voor diefstal.",
  alarm_system: "Alarmsystemen",
  alarm_system_desc:
    "Installatie van een alarm en een sirene van de aanwezigheid van verschillende niveaus van antidiefstalbeveiliging.",
  steering_wheel_locks: "Stuursloten",
  steering_wheel_locks_desc:
    "Een goede manier om uw auto te beschermen tegen diefstal is het gebruik van stuursloten",
  hidding_keys: "De sleutels verbergen",
  hidding_keys_desc:
    "Laat uw sleutels nooit in het zicht liggen, ze kunnen een dief verleiden.",
  parking_on_private_areas: "Parkeren op eigen terrein",
  parking_on_private_areas_desc:
    "Als u de voorkeur geeft aan parkeerplaatsen op eigen terrein, weet u zeker dat uw voertuig veilig staat.",
  how_to_prevent_theft: "Hoe diefstal te voorkomen",
  secure_parking: "Veilig parkeren",
  secure_parking_desc:
    "Een van de beste manieren om uw auto tegen diefstal te beschermen, is door hem op een plek te parkeren die 's nachts bewaakt en verlicht is. Zoek naar parkeerplaatsen met camera's, alarmsystemen of toezichthoudend personeel.",
  indoor_car_parking: "Overdekte parkeergarages",
  indoor_car_parking_desc:
    "Bescherm uw auto tegen weersinvloeden en diefstal door gebruik te maken van de overdekte parking in het stadscentrum.",
  limited_availability: "Gelimiteerde beschikbaarheid",
  limited_availability_desc:
    "Kies een parkeerplaats met beperkte toegang en wachtwoorden, maak het dieven moeilijk.",
  megaparks: "Megaparken",
  megaparks_desc:
    "Als je gebruik maakt van een megaparking, zorg er dan voor dat je auto dicht bij de ingang geparkeerd staat en goed zichtbaar is.",
  monitored_parking: "Bewaakt parkeerterrein",
  monitored_parking_desc:
    "Zoek naar parkeerplaatsen die worden bewaakt door camera's en sluit uw auto altijd af, ook als u deze maar even laat staan.",
  summary: "Samenvatting",
  summary_desc:
    "Autodiefstal is wereldwijd een serieus probleem. De toename van het aantal voertuigen op de weg is bevorderlijk voor de toenemende activiteit van criminelen. Gestolen auto's worden vaak verkocht voor onderdelen of geëxporteerd naar het buitenland, waardoor het moeilijk wordt voor hun eigenaren om ze terug te vinden. Politieorganisaties en overheidsinstellingen ondernemen tal van acties om dit soort criminaliteit te voorkomen en te bestrijden, waaronder het verbeteren van beveiligingstechnologie en internationale samenwerking bij de vervolging van criminelen. Ook educatie en bewustwording van automobilisten zijn belangrijk om situaties die autodiefstal in de hand werken te voorkomen",
};
