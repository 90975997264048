export const lt = {
  free_vehicle_check: "Nemokama automobilio patikra",
  enter_vin_number: "Įveskite VIN numerį",
  must_be_17_characters: "Turi būti 17 simbolių ilgio",
  check_vehicle: "Patikrinkite transporto priemonę",
  buying_used_car_check_vehicle_desc:
    "Ar perkate naudotą automobilį? Patikrinkite automobilį mūsų duomenų bazėje!",
  database_have_records_desc:
    "Savo duomenų bazėje turime daugiau nei 140 000 vogtų transporto priemonių įrašų. Duomenų baze naudojasi daugelis institucijų, inspektorių ir keičiasi informacinėmis sistemomis apie vogtas transporto priemones.",
  checking_vehicle: "Tikrinama transporto priemonė",
  proccessing_please_wait: "Apdorojama – palaukite",
  please_wait_takes_moment: "Prašau palauk. Tai užtrunka tik akimirką.",
  vehicle_not_found: "Transporto priemonė nerasta",
  vehicle_found: "Transporto priemonė rasta",
  vehicle_does_not_appear_database:
    "Automobilis nepatenka į vogtų duomenų bazę.",
  vehicle_appear_database:
    "Transporto priemonė yra pavogtų transporto priemonių duomenų bazėje",
  check_another_vehicle: "Patikrinkite kitą transporto priemonę",
  check_vehicle_history: "Patikrinkite automobilio istoriją",
  get_more_information: "Gaukite daugiau informacijos",
  incidents_history: "Įvykių istorija",
  mileage_reading: "Kilometražo skaitymas",
  vehicle_inspections: "Transporto priemonių patikros",
  archival_photo: "Archyvinė nuotrauka",
  and_more: "ir dar",
  check_now: "Patikrink Dabar",
  all_rights_reserved: "Visos teisės saugomos.",
  entered_vin_number: "Įvestas VIN numeris",
  may_be_incorrect: "Tai gali būti neteisinga",
  correct: "Teisingai",
  check: "Patikrinti",
  incorrect: "Neteisinga",
  check_again: "Patikrink dar kartą",
  entered_vin_is_incorrect:
    "Nurodyto VIN duomenų nėra arba numeris neteisingas",
  reenter_your_vin: "Iš naujo įveskite savo vin numerį",
  accept: "Priimti",
  regulations: "Reglamentas",
  back: "Atgal",
  last_checked_vins: "Neseniai patikrinti VIN",
  enter_plate: "Transporto priemonės valstybinis numeris",
  enter_country_code: "Šalies kodas",
  value_cannot_be_empty: "Vertė negali būti tuščia",
  fast_and_reliable_stolen:
    "Greitas ir patikimas vogtų automobilių duomenų bazės patikrinimas",
  welcome_our_website_desc:
    "Sveiki atvykę į mūsų tinklalapį, kuriame galite patikrinti <strong>vogtų automobilių duomenų bazes</strong> . Jei įtariate, kad jūsų automobilis buvo pavogtas, arba norite <strong>apsisaugoti nuo vogtos transporto priemonės įsigijimo</strong> , mūsų paslauga gali jums padėti.",
  using_our_advanced_search_tools_desc:
    "Naudodami mūsų išplėstinės paieškos įrankius, vos keliais paspaudimais galite lengvai patikrinti kelias <strong>vogtų automobilių</strong> duomenų bazes. Tiesiog įveskite atitinkamo automobilio VIN arba numerio numerį ir mes ieškosime savo <strong>didelėje duomenų bazėje</strong> , kad pamatytume, ar apie tai buvo pranešta kaip apie vagystę.",
  our_service_is_fast_desc:
    "Mūsų paslauga yra greita, patikima ir paprasta naudoti, todėl reguliariai atnaujiname savo duomenų bazes, siekdami užtikrinti, kad turėtumėte tiksliausią ir naujausią informaciją. Be to, naudodamiesi patogia vartotojo sąsaja galite greitai ir lengvai patikrinti, ar nėra pavogtų automobilių patogiai savo namuose.",
  do_not_take_the_risk_of_buying_desc:
    "Taigi nerizikuokite pirkti vogtą automobilį ar vairuoti jį nežinodami – pasinaudokite mūsų vogtų automobilių duomenų bazės patikra, kad apsaugotumėte save ir savo investicijas. Pradėkite paiešką dabar! Tai visiškai nemokama",
  help: "Pagalba",
  check_if_vin_correct: "Patikrinkite VIN numerio teisingumą",
  check_valuation: "Peržiūrėkite nemokamą automobilio įvertinimą",
  decode_vin_number: "Iššifruokite VIN numerį",
  additional_links: "Papildomos nuorodos",

  dangerous_models: "Saugiausi modeliai",
  most_popular: "Populiariausi modeliai",
  most_expensive: "Brangiausi modeliai",
  according_stats_desc:
    "Pagal statistiką, senesni modeliai su apsaugos sistemomis yra mažiausiai jautrūs vagystėms.",
  most_frequently_desc:
    "Dažniausiai vagiami modeliai yra populiarių markių ir modelių, tokių kaip „Volkswagen Golf“ ar „Ford Focus“.",
  luxury_cars_such_as_desc:
    "Prabangūs automobiliai, tokie kaip „Porsche“, „Lamborghini“ ir „Ferrari“, taip pat yra labiausiai pažeidžiami vagysčių.",
  alarm_system: "Signalizacijos sistemos",
  alarm_system_desc:
    "Signalizacijos ir kelių apsaugos nuo vagystės lygių sirenos įrengimas.",
  steering_wheel_locks: "Vairo užraktai",
  steering_wheel_locks_desc:
    "Geras būdas apsaugoti automobilį nuo vagystės – naudoti vairo užraktus",
  hidding_keys: "Raktų slėpimas",
  hidding_keys_desc:
    "Niekada nepalikite raktų matomoje vietoje, nes jie gali suvilioti vagį.",
  parking_on_private_areas: "Parkavimas privačioje aikštelėje",
  parking_on_private_areas_desc:
    "Pirmenybę teikdami parkavimo vietoms privačioje žemėje, galite būti tikri, kad jūsų transporto priemonė yra saugi.",
  how_to_prevent_theft: "Kaip apsisaugoti nuo vagystės",
  secure_parking: "Saugi automobilių stovėjimo aikštelė",
  secure_parking_desc:
    "Vienas geriausių būdų apsaugoti automobilį nuo vagystės – pastatyti jį stebimoje ir naktį apšviestoje vietoje. Ieškokite automobilių stovėjimo aikštelių, kuriose yra kameros, signalizacijos sistemos arba darbuotojai.",
  indoor_car_parking: "Dengtos automobilių stovėjimo aikštelės",
  indoor_car_parking_desc:
    "Apsaugokite savo automobilį nuo oro sąlygų ir vagysčių naudodamiesi dengta automobilių stovėjimo aikštele miesto centre.",
  limited_availability: "Ribotas prieinamumas",
  limited_availability_desc:
    "Pasirinkite automobilių stovėjimo aikštelę su apribota prieiga ir slaptažodžiais, todėl vagims bus sunku.",
  megaparks: "Megaparkai",
  megaparks_desc:
    "Jei naudojatės didele automobilių stovėjimo aikštele, įsitikinkite, kad jūsų automobilis stovi netoli įėjimo ir yra aiškiai matomas.",
  monitored_parking: "Stebima automobilių stovėjimo aikštelė",
  monitored_parking_desc:
    "Ieškokite CCTV stebimų parkavimo vietų ir visada užrakinkite automobilį, net jei iš jo trumpam išvažiuojate.",
  summary: "Santrauka",
  summary_desc:
    "Automobilių vagystės yra rimta problema visame pasaulyje. Didėjantis transporto priemonių skaičius keliuose yra palankus didėjančiam nusikaltėlių aktyvumui. Vogti automobiliai dažnai parduodami dalimis arba išvežami į užsienį, todėl jų savininkams sunku juos atgauti. Policijos organizacijos ir vyriausybinės institucijos imasi daugybės veiksmų, siekdamos užkirsti kelią šio tipo nusikaltimams ir su jais kovoti, įskaitant saugumo technologijų tobulinimą ir tarptautinį bendradarbiavimą persekiojant nusikaltėlius. Vairuotojų švietimas ir sąmoningumas taip pat yra svarbūs siekiant užkirsti kelią situacijoms, kurios skatina automobilio vagystę",
};
