<template>
  <div>
    <div id="form">
      <Form />
    </div>

    <div id="description">
      <DescriptionSection/>
    </div>

    <div id="lastCheckedVins">
      <LastCheckedVins />
    </div>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import Form from "../components/Form";
import DescriptionSection from "../components/DescriptionSection/DescriptionSection.vue";
import LastCheckedVins from "../components/LastCheckedVins";
import { useStore } from "vuex";

export default {
  name: "Home",
  components: {
    Form,
    DescriptionSection,
    LastCheckedVins,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      return store.dispatch("setCheckedVin", "");
    });
  },
};
</script>