export const ru = {
  free_vehicle_check: "Бесплатная проверка автомобиля",
  enter_vin_number: "Введите вин-код",
  must_be_17_characters: "Должно быть 17 символов",
  check_vehicle: "Проверить автомобиль",
  buying_used_car_check_vehicle_desc:
    "Вы покупаете подержанный автомобиль? Проверьте автомобиль в нашей базе данных!",
  database_have_records_desc:
    "В нашей базе данных более 140 000 записей об угнанных автомобилях. База данных используется многими учреждениями, инспекторами и системами обмена информацией об угнанных автомобилях.",
  checking_vehicle: "Проверка автомобиля",
  proccessing_please_wait: "Обработка - подождите",
  please_wait_takes_moment: "Пожалуйста, подождите. Это займет всего минуту.",
  vehicle_not_found: "Автомобиль не найден",
  vehicle_found: "Автомобиль найден",
  vehicle_does_not_appear_database:
    "Автомобиль не фигурирует в базе украденных.",
  vehicle_appear_database:
    "Автомобиль появляется в базе данных угнанных автомобилей.",
  check_another_vehicle: "Проверить другое транспортное средство",
  check_vehicle_history: "Проверить историю автомобиля",
  get_more_information: "Получить больше информации",
  incidents_history: "История инцидентов",
  mileage_reading: "Чтение пробега",
  vehicle_inspections: "Осмотр транспортных средств",
  archival_photo: "Архивное фото",
  and_more: "и больше",
  check_now: "Проверь сейчас",
  all_rights_reserved: "Все права защищены.",
  entered_vin_number: "Введенный VIN-номер",
  may_be_incorrect: "Это может быть неправильно",
  correct: "Правильный",
  check: "Проверить",
  incorrect: "Неправильно",
  check_again: "Проверьте еще раз",
  entered_vin_is_incorrect:
    "Нет данных для данного VIN или номер недействителен",
  reenter_your_vin: "Пожалуйста, введите еще раз свой вин номер",
  accept: "Принимать",
  regulations: "Правила",
  back: "Назад",
  last_checked_vins: "Недавно проверенные VIN",
  enter_plate: "Номерной знак транспортного средства",
  enter_country_code: "Код страны",
  value_cannot_be_empty: "Значение не может быть пустым",
  fast_and_reliable_stolen:
    "Быстрая и надежная проверка базы данных угнанных автомобилей",
  welcome_our_website_desc:
    "Добро пожаловать на наш сайт по проверке <strong>баз данных угнанных автомобилей</strong> . Если вы подозреваете, что ваш автомобиль угнали, или хотите <strong>обезопасить себя от покупки угнанного автомобиля</strong> , наш сервис может вам помочь.",
  using_our_advanced_search_tools_desc:
    "Используя наши расширенные инструменты поиска, вы можете легко проверить несколько баз данных на наличие <strong>угнанных автомобилей</strong> всего за несколько кликов. Просто введите VIN или регистрационный номер автомобиля, о котором идет речь, и мы проведем поиск по нашей <strong>обширной базе данных</strong> , чтобы узнать, не было ли заявлено об угоне автомобиля.",
  our_service_is_fast_desc:
    "Наш сервис быстрый, надежный и простой в использовании, и мы регулярно обновляем наши базы данных, чтобы гарантировать, что у вас есть самая точная и актуальная информация. Кроме того, благодаря нашему удобному интерфейсу вы можете быстро и легко проверить наличие угнанных автомобилей, не выходя из собственного дома.",
  do_not_take_the_risk_of_buying_desc:
    "Так что не рискуйте, покупая угнанный автомобиль или управляя им, не зная об этом — используйте нашу проверку базы данных угнанных автомобилей, чтобы защитить себя и свои инвестиции. Начните поиск прямо сейчас! Это совершенно бесплатно",
  help: "Помощь",
  check_if_vin_correct: "Проверить правильность VIN номера",
  check_valuation: "Получите бесплатную оценку автомобиля",
  decode_vin_number: "Расшифровать вин номер",
  additional_links: "Дополнительные ссылки",

  dangerous_models: "Самые безопасные модели",
  most_popular: "Самые популярные модели",
  most_expensive: "самые дорогие модели",
  according_stats_desc:
    "По статистике, старые модели с системами безопасности меньше всего подвержены угону.",
  most_frequently_desc:
    "Наиболее часто угоняемые модели — это популярные марки и модели, такие как Volkswagen Golf или Ford Focus.",
  luxury_cars_such_as_desc:
    "Роскошные автомобили, такие как Porsche, Lamborghini и Ferrari, также наиболее уязвимы для угона.",
  alarm_system: "Системы сигнализации",
  alarm_system_desc:
    "Установка сигнализации и сирены наличия нескольких уровней противоугонной защиты.",
  steering_wheel_locks: "Рулевые замки",
  steering_wheel_locks_desc:
    "Хороший способ защитить свой автомобиль от угона — использовать замки руля.",
  hidding_keys: "Скрытие ключей",
  hidding_keys_desc:
    "Никогда не оставляйте ключи на виду, они могут соблазнить вора.",
  parking_on_private_areas: "Парковка на частной территории",
  parking_on_private_areas_desc:
    "Отдавая предпочтение парковочным местам на частных землях, вы можете быть уверены в безопасности своего транспортного средства.",
  how_to_prevent_theft: "Как предотвратить кражу",
  secure_parking: "Охраняемая парковка",
  secure_parking_desc:
    "Один из лучших способов защитить свой автомобиль от угона — припарковать его в охраняемом и освещенном в ночное время месте. Ищите парковочные места, на которых установлены камеры, системы сигнализации или персонал для наблюдения.",
  indoor_car_parking: "Крытые автостоянки",
  indoor_car_parking_desc:
    "Защитите свой автомобиль от непогоды и угона, воспользовавшись крытой парковкой в центре города.",
  limited_availability: "Ограниченная доступность",
  limited_availability_desc:
    "Выберите парковку с ограниченным доступом и паролями, что затруднит воровство.",
  megaparks: "Мегапарки",
  megaparks_desc:
    "Если вы пользуетесь мегапарковкой, убедитесь, что ваша машина припаркована близко к входу и хорошо видна.",
  monitored_parking: "охраняемая автостоянка",
  monitored_parking_desc:
    "Ищите парковочные места, которые находятся под наблюдением видеонаблюдения, и всегда запирайте свой автомобиль, даже если вы покидаете его ненадолго.",
  summary: "Краткое содержание",
  summary_desc:
    "Кража автомобилей является серьезной проблемой во всем мире. Увеличение количества транспортных средств на дорогах способствует повышению активности преступников. Угнанные автомобили часто продаются на запчасти или вывозятся за границу, что затрудняет их возвращение владельцам. Полицейские организации и государственные учреждения принимают многочисленные меры для предотвращения и борьбы с этим видом преступлений, включая совершенствование технологий безопасности и международное сотрудничество в судебном преследовании преступников. Образование и информированность водителей также важны для предотвращения ситуаций, способствующих угону автомобилей.",
};
