<template>
  <div
    class="
      position-relative
      overflow-hidden
      p-3 p-md-5
      m-md-3
      text-center
      bg-grey
      rounded-3
      mb-4
    "
  >
    <div class="col-md-12 p-lg-7 mx-auto my-2 my-md-5">
      <h1 class="display-4 fw-normal">{{ t("free_vehicle_check") }}</h1>

      <div class="row g-3 align-items-center text-center">
        <div class="col-md-6">
          <img
            src="../assets/img/icon-stolen.jpg"
            alt="Check Stolen Cars Database"
            class="my-2 mx-auto"
          />
        </div>
        <div class="col-md-6">
          <div class="row">
            <label for="vin" class="col-form-label fw-bolder">{{
              t("enter_vin_number")
            }}</label>
            <div class="col-12 d-flex justify-content-center">
              <input
                type="text"
                id="vin"
                class="form-control"
                aria-describedby="checkHelpInline"
                maxlength="17"
                minlength="17"
                v-model="vin"
                v-on:keyup.enter="checkForm"
              />
            </div>
            <span v-if="isVinInvalid" id="checkHelpInline" class="form-text">
              {{ t("must_be_17_characters") }}
            </span>
            <div class="col-4">
              <label for="inputCountryCode" class="col-form-label fw-bolder">{{
                t("enter_country_code")
              }}</label>
              <select
                v-model="countryCode"
                class="form-control text-center"
                required
              >
                <option
                  v-for="(countryCode2Char, country, index) in countriesPlate"
                  :key="index"
                  :value="countryCode2Char"
                >
                  {{ country }}
                </option>
              </select>
            </div>
            <div class="col-8">
              <label for="plate" class="col-form-label fw-bolder">{{
                t("enter_plate")
              }}</label>
              <input
                type="text"
                id="plate"
                class="form-control"
                v-model="plate"
                v-on:keyup.enter="checkForm"
                aria-describedby="plateHelper"
              />
              <span v-if="isPlateInvalid" id="plateHelper" class="form-text">
                {{ t("value_cannot_be_empty") }}
              </span>
            </div>
            <div class="col-12 d-flex justify-content-center mt-2">
              <vue-recaptcha
                v-show="message == 'not_pass'"
                sitekey="6Lc8TvMZAAAAAJJWO8h_TtYMs-FJ9yuzy0oqUdOd"
                size="normal"
                theme="light"
                @verify="recaptchaVerified"
                @expire="recaptchaExpired"
                @fail="recaptchaFailed"
                ref="vueRecaptcha"
              >
              </vue-recaptcha>
              <!-- 6Lc8TvMZAAAAAJJWO8h_TtYMs-FJ9yuzy0oqUdOd -->
            </div>
          </div>

          <div class="row">
            <div
              class="
                col-lg-6 col-sm-12
                d-flex
                align-items-center
                justify-content-lg-end justify-content-center
              "
            ></div>

            <div class="col-12 d-flex justify-content-center">
              <Button
                classes="my-2 btn"
                @check="checkForm"
                :disabled="!enableBtn"
              >
                {{ t("check_vehicle") }}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2 class="mt-4">
          {{ t("buying_used_car_check_vehicle_desc") }}
        </h2>
        <p class="fw-normal">
          {{ t("database_have_records_desc") }}
        </p>
      </div>

      <div>
        <h2 class="mt-4">
          {{ t("fast_and_reliable_stolen") }}
        </h2>
        <p class="fw-normal" v-html="t('welcome_our_website_desc')">
        </p>
        <p class="fw-normal" v-html="t('using_our_advanced_search_tools_desc')">
        </p>
        <p class="fw-normal">
          {{  t('our_service_is_fast_desc')  }}
        </p>
        <p class="fw-normal">
          {{  t('do_not_take_the_risk_of_buying_desc')  }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import sendReq from "@/composables/sendReq";
import checkResponse from "@/composables/checkResponse";
import Button from "./Button";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import vueRecaptcha from "vue3-recaptcha2";
import { countryCodesAvailable, langToPlate } from "../utils/config";

export default {
  name: "Form",
  components: {
    Button,
    vueRecaptcha,
  },
  setup() {
    const store = useStore();
    const vin = ref("");
    let errors = reactive([]);
    const { sendForm, response } = sendReq();
    const { t, locale } = useI18n();
    const router = useRouter();
    const token = ref("TOKEN");
    const enableBtn = ref(true);
    const plate = ref("");
    const countriesPlate = ref(countryCodesAvailable);
    const isVinInvalid = ref(false);
    const isPlateInvalid = ref(false);
    const countryCode = computed(() => {
      return langToPlate(store.state.lang);
    });

    //captcha start
    const recaptchaVerified = (response) => {
      token.value = response;
      store.commit("updateIsRobot", false);
    };

    const recaptchaExpired = () => {
      store.commit("updateIsRobot", true);
      this.$refs.vueRecaptcha.reset();
    };

    const recaptchaFailed = (response) => {
      console.log("failed", response);
    };

    //captcha end

    const checkForm = async () => {
      isVinInvalid.value = false;
      isPlateInvalid.value = false;
      errors.splice(0);
      if (vin.value.length != 17) {
        isVinInvalid.value = true;
        errors.push("Numer VIN musi zawierać 17 znaków");
      }
      if (!plate.value) {
        isPlateInvalid.value = true;
        errors.push("type_plate");
      }

      if (errors.length == 0) {
        store.dispatch("setCheckedVin", vin.value);
        router.push({
          name: "checkingVin",
          params: {
            vin: vin.value,
          },
        });

        const send = await sendForm(
          vin.value,
          true,
          token.value,
          plate.value,
          countryCode.value
        );

        if (send == true) {
          const { directionRouter } = checkResponse(response.value);

          const direction = directionRouter();

          if (direction.params.vin) {
            router.push({
              name: direction.name,
              params: {
                vin: vin.value,
              },
            });
          } else {
            router.push({
              name: direction.name,
            });
          }
        } else {
          if (send == "REQUEST_CANCELED") {
            return;
          } else {
            store.commit("updateMessage", "internal_error");
            router.push({
              name: "home",
            });
          }
        }
      }
    };

    //do przeniesienia start
    const updateVinValidator = (result) => {
      store.commit("updateVinValidator", {
        result,
      });
    };

    const updateMessage = (status) => {
      store.commit("updateMessage", status);
    };

    const updateStolen = (status) => {
      store.commit("updateStolen", status);
    };

    const message = computed(() => {
      return store.state.message;
    });

    //do przeniesienia end

    return {
      vin,
      plate,
      countryCode,
      countriesPlate,
      errors,
      checkForm,
      updateStolen,
      updateVinValidator,
      checkResponse,
      updateMessage,
      t,
      locale,
      recaptchaVerified,
      recaptchaExpired,
      recaptchaFailed,
      enableBtn,
      message,
      isVinInvalid,
      isPlateInvalid,
    };
  },
};
</script>

<style scoped>
input {
  text-align: center;
}
</style>