export const pt = {
  free_vehicle_check: "Verificação gratuita do veículo",
  enter_vin_number: "Digite o número VIN",
  must_be_17_characters: "Deve ter 17 caracteres",
  check_vehicle: "Verificar veículo",
  buying_used_car_check_vehicle_desc:
    "Você está comprando um carro usado? Verifique veículo em nosso banco de dados!",
  database_have_records_desc:
    "Em nosso banco de dados temos mais de 140.000 registros de veículos roubados. O banco de dados é utilizado por muitas instituições, inspetores e sistemas de troca de informações sobre veículos roubados.",
  checking_vehicle: "Verificação do veículo",
  proccessing_please_wait: "Processando ... Por favor aguarde",
  please_wait_takes_moment: "Por favor, aguarde. Leva apenas um momento.",
  vehicle_not_found: "Veículo não encontrado",
  vehicle_found: "Veículo encontrado",
  vehicle_does_not_appear_database:
    "O veículo não aparece no banco de dados roubado.",
  vehicle_appear_database:
    "O veículo aparece no banco de dados de veículos roubados",
  check_another_vehicle: "Verifique outro veículo",
  check_vehicle_history: "Verifique o histórico do veículo",
  get_more_information: "Consiga mais informação",
  incidents_history: "Histórico de incidentes",
  mileage_reading: "Leitura de quilometragem",
  vehicle_inspections: "Inspeções de veículos",
  archival_photo: "Foto de arquivo",
  and_more: "e mais",
  check_now: "Verifique agora",
  all_rights_reserved: "Todos os direitos reservados.",
  entered_vin_number: "O número VIN inserido",
  may_be_incorrect: "Pode estar incorreto",
  correct: "Correto",
  check: "Verificar",
  incorrect: "Incorreta",
  check_again: "Verifique novamente",
  entered_vin_is_incorrect:
    "Não há dados para o VIN fornecido ou o número é inválido",
  reenter_your_vin: "Por favor, reinsira o seu número vin",
  accept: "Aceitar",
  regulations: "Regulamentos",
  back: "Voltar",
  last_checked_vins: "VINs verificados recentemente",
  enter_plate: "Placa de matrícula do veículo",
  enter_country_code: "Código do país",
  value_cannot_be_empty: "O valor não pode estar vazio",
  fast_and_reliable_stolen:
    "Verificação rápida e confiável do banco de dados de carros roubados",
  welcome_our_website_desc:
    "Bem-vindo ao nosso site para verificação <strong>de bancos de dados de carros roubados</strong> . Se você suspeita que seu carro foi roubado ou deseja se <strong>proteger da compra de um veículo roubado</strong> , nosso serviço pode ajudá-lo.",
  using_our_advanced_search_tools_desc:
    "Usando nossas ferramentas de pesquisa avançada, você pode verificar facilmente vários bancos de dados de <strong>carros roubados</strong> com apenas alguns cliques. Basta inserir o VIN ou o número da placa do carro em questão e faremos uma busca em nosso <strong>extenso banco</strong> de dados para ver se ele foi relatado como roubado.",
  our_service_is_fast_desc:
    "Nosso serviço é rápido, confiável e fácil de usar, e atualizamos nossos bancos de dados regularmente para garantir que você tenha as informações mais precisas e atualizadas. Além disso, com nossa interface amigável, você pode verificar carros roubados de forma rápida e fácil no conforto de sua própria casa.",
  do_not_take_the_risk_of_buying_desc:
    "Portanto, não corra o risco de comprar um carro roubado ou dirigir um sem saber - use nossa verificação do banco de dados de carros roubados para proteger você e seu investimento. Comece sua pesquisa agora! é totalmente gratuito",
  help: "Ajuda",
  check_if_vin_correct: "Verifique a exatidão do número VIN",
  check_valuation: "Confira uma avaliação gratuita do veículo",
  decode_vin_number: "Decodificar número VIN",
  additional_links: "Links adicionais",

  dangerous_models: "Os modelos mais seguros",
  most_popular: "Os modelos mais populares",
  most_expensive: "Os modelos mais caros",
  according_stats_desc:
    "Segundo as estatísticas, os modelos mais antigos com sistemas de segurança são os menos suscetíveis a roubos.",
  most_frequently_desc:
    "Os modelos mais roubados são marcas e modelos populares, como Volkswagen Golf ou Ford Focus.",
  luxury_cars_such_as_desc:
    "Carros de luxo como Porsche, Lamborghini e Ferrari também são os mais vulneráveis a roubos.",
  alarm_system: "Sistemas de Alarme",
  alarm_system_desc:
    "Instalação de alarme e sirene da presença de vários níveis de proteção antifurto.",
  steering_wheel_locks: "travas de direção",
  steering_wheel_locks_desc:
    "Uma boa maneira de proteger seu carro contra roubo é usar travas de direção",
  hidding_keys: "Escondendo as chaves",
  hidding_keys_desc:
    "Nunca deixe suas chaves à vista, pois elas podem tentar um ladrão.",
  parking_on_private_areas: "Estacionamento em terrenos privados",
  parking_on_private_areas_desc:
    "Preferindo vagas de estacionamento em terrenos privados, você pode ter certeza de que seu veículo está seguro.",
  how_to_prevent_theft: "Como prevenir o roubo",
  secure_parking: "estacionamento seguro",
  secure_parking_desc:
    "Uma das melhores formas de proteger seu carro contra furtos é estacioná-lo em um local monitorado e iluminado à noite. Procure vagas de estacionamento que tenham câmeras, sistemas de alarme ou funcionários postados para monitorar.",
  indoor_car_parking: "Estacionamentos cobertos",
  indoor_car_parking_desc:
    "Proteja seu carro das intempéries e roubos usando o estacionamento coberto no centro da cidade.",
  limited_availability: "Disponibilidade limitada",
  limited_availability_desc:
    "Escolha um estacionamento com acesso restrito e senhas, dificultando a entrada de ladrões.",
  megaparks: "Megaparques",
  megaparks_desc:
    "Se você usar um mega estacionamento, certifique-se de que seu carro esteja estacionado próximo à entrada e esteja bem visível.",
  monitored_parking: "Estacionamento monitorado",
  monitored_parking_desc:
    "Procure lugares de estacionamento monitorizados por CCTV e tranque sempre o seu carro, mesmo que se afaste pouco tempo.",
  summary: "Resumo",
  summary_desc:
    "O roubo de carros é um problema sério em todo o mundo. O aumento do número de veículos nas estradas é propício para o aumento da atividade de criminosos. Carros roubados costumam ser vendidos para peças ou exportados para o exterior, dificultando a recuperação de seus proprietários. Organizações policiais e instituições governamentais realizam inúmeras ações para prevenir e combater esse tipo de crime, incluindo o aprimoramento da tecnologia de segurança e a cooperação internacional no julgamento de criminosos. Educação e conscientização dos motoristas também são importantes para prevenir situações que favorecem o roubo de veículos",
};
