import { createStore } from "vuex";
import { defaultState } from "../utils/config";
import { useI18n } from "vue-i18n";

export default createStore({
  state() {
    return defaultState();
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
    lastCheckedVins(state) {
      return state.lastCheckedVins;
    },
    precheckStatus(state) {
      return state.precheckStatus;
    }
  },
  mutations: {
    updateVinValidator(state, payload) {
      const res = payload.result;
      state.result = {
        vin_alternatives_list: res.vin_alternatives_list,
        vin_correct: res.vin_correct,
        vin_orginal: res.vin_orginal,
      };
    },
    updateMessage(state, payload) {
      state.message = payload;
    },
    updateStolen(state, payload) {
      state.stolen = payload;
    },
    updateShowCookie(state, payload) {
      state.showCookie = payload;
    },
    updateCheckedVin(state, payload) {
      state.checkedVin = payload;
    },
    updatePrecheckData(state, payload) {
      state.precheck = {
        odometers: payload.odometers,
        damages: payload.damages,
        events: payload.events,
        photos: payload.photos,
      };
    },
    updatePrecheckStatus(state, payload) {
      state.precheckStatus = payload;
    },
    updateLang(state, payload) {
      state.lang = payload;
    },
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    },
    resetData(state) {
      for (const singleData in state) {
        if (singleData == 'lastCheckedVins') {
          continue;
        }
        state[singleData] = defaultState()[singleData];
      }
    },
    updateLastCheckedVins(state, payload) {
      state.lastCheckedVins = payload;
    },
    updateIsRobot(state, payload) {
      state.isRobot = payload;
    }
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      context.state.cancelTokens.forEach((request) => {
        if (request.cancel) {
          request.cancel("REQUEST_CANCELED");
        }
      });

      context.commit("CLEAR_CANCEL_TOKENS");
    },
    async RESET_STATE(context) {
      context.commit("resetData");
      await this.dispatch("SET_LANG");
    },
    SET_LANG(context) {
      const { locale } = useI18n();
      const index = locale.value.indexOf("-");
      let result = null;

      if (index < 0) {
        result = locale.value;
      } else {
        result = locale.value.slice(0, index);
      }
      context.commit("updateLang", result);
    },
    setCheckedVin({ commit }, payload) {
      commit('updateCheckedVin', payload);
    },
    addLastCheckedVins({ commit }, payload) {
      commit('updateLastCheckedVins', payload);
    },
    setPrecheckStatus({ commit }, payload) {
      commit("updatePrecheckStatus", payload);
    }
  },
});
