export const ar = {
  free_vehicle_check: "فحص مجاني للمركبة",
  enter_vin_number: "أدخل رقم VIN",
  must_be_17_characters: "يجب أن يكون طوله 17 حرفًا",
  check_vehicle: "افحص السيارة",
  buying_used_car_check_vehicle_desc:
    "أنت تشتري سيارة مستعملة؟ تحقق من السيارة في قاعدة بياناتنا!",
  database_have_records_desc:
    "في قاعدة البيانات الخاصة بنا ، لدينا أكثر من 140000 سجل للمركبات المسروقة. يتم استخدام قاعدة البيانات من قبل العديد من المؤسسات والمفتشين وتبادل نظم المعلومات حول المركبات المسروقة.",
  checking_vehicle: "فحص السيارة",
  proccessing_please_wait: "جارى المعالجة .. انتظر من فضلك",
  please_wait_takes_moment: "أرجو الإنتظار. يستغرق سوى لحظة.",
  vehicle_not_found: "المركبة غير موجودة",
  vehicle_found: "وجدت السيارة",
  vehicle_does_not_appear_database:
    "المركبة لا تظهر في قاعدة البيانات المسروقة.",
  vehicle_appear_database: "تظهر السيارة في قاعدة بيانات المركبات المسروقة",
  check_another_vehicle: "افحص مركبة أخرى",
  check_vehicle_history: "تحقق من تاريخ السيارة",
  get_more_information: "احصل على معلومات اكثر",
  incidents_history: "تاريخ الحوادث",
  mileage_reading: "قراءة الأميال",
  vehicle_inspections: "فحص المركبات",
  archival_photo: "صورة أرشيفية",
  and_more: "و اكثر",
  check_now: "تأكد الان",
  all_rights_reserved: "كل الحقوق محفوظة.",
  entered_vin_number: "رقم VIN الذي تم إدخاله",
  may_be_incorrect: "قد يكون غير صحيح",
  correct: "صيح",
  check: "يفحص",
  incorrect: "غير صحيح",
  check_again: "تحقق مرة اخرى",
  entered_vin_is_incorrect:
    "لا توجد بيانات لرقم VIN المحدد أو أن الرقم غير صالح",
  reenter_your_vin: "الرجاء إعادة إدخال رقم فين الخاص بك",
  accept: "قبول",
  regulations: "أنظمة",
  back: "خلف",
  last_checked_vins: "تم فحص VINs مؤخرًا",
  enter_plate: "لوحة تسجيل المركبة",
  enter_country_code: "الرقم الدولي",
  value_cannot_be_empty: "لا يمكن أن تكون القيمة فارغة",
  fast_and_reliable_stolen: "فحص سريع وموثوق لقاعدة بيانات السيارات المسروقة",
  welcome_our_website_desc:
    "مرحبًا بكم في موقعنا على الإنترنت للتحقق من <strong>قواعد بيانات السيارات المسروقة</strong> . إذا كنت تشك في أن سيارتك قد سُرقت ، أو إذا كنت تريد <strong>حماية نفسك من شراء سيارة مسروقة</strong> ، فيمكن أن تساعدك خدمتنا.",
  using_our_advanced_search_tools_desc:
    "باستخدام أدوات البحث المتقدمة الخاصة بنا ، يمكنك بسهولة التحقق من قواعد بيانات متعددة <strong>للسيارات المسروقة</strong> ببضع نقرات. ما عليك سوى إدخال رقم VIN أو رقم لوحة السيارة المعنية ، وسنبحث في <strong>قاعدة البيانات الشاملة</strong> الخاصة بنا لمعرفة ما إذا تم الإبلاغ عن سرقتها.",
  our_service_is_fast_desc:
    "خدمتنا سريعة وموثوقة وسهلة الاستخدام ، ونقوم بتحديث قواعد البيانات الخاصة بنا على أساس منتظم لضمان حصولك على المعلومات الأكثر دقة وحداثة. بالإضافة إلى ذلك ، من خلال واجهتنا سهلة الاستخدام ، يمكنك البحث بسرعة وسهولة عن السيارات المسروقة وأنت مرتاح في منزلك.",
  do_not_take_the_risk_of_buying_desc:
    "لذلك لا تخاطر بشراء سيارة مسروقة أو قيادة واحدة دون أن تعرف - استخدم فحص قاعدة بيانات السيارات المسروقة لحماية نفسك واستثمارك. ابدأ البحث الآن! إنه مجاني تمامًا",
  help: "يساعد",
  check_if_vin_correct: "تحقق من صحة رقم VIN",
  check_valuation: "تحقق من التقييم المجاني للسيارة",
  decode_vin_number: "فك شفرة رقم VIN",
  additional_links: "روابط إضافية",

  dangerous_models: "أسلم الموديلات",
  most_popular: "النماذج الأكثر شعبية",
  most_expensive: "أغلى الموديلات",
  according_stats_desc:
    "وفقًا للإحصاءات ، فإن النماذج القديمة ذات الأنظمة الأمنية هي الأقل عرضة للسرقة.",
  most_frequently_desc:
    "النماذج الأكثر تعرضًا للسرقة هي الماركات والموديلات الشهيرة ، مثل فولكس فاجن جولف أو فورد فوكس.",
  luxury_cars_such_as_desc:
    "السيارات الفاخرة مثل بورش ولامبورغيني وفيراري هي أيضا الأكثر عرضة للسرقة.",
  alarm_system: "أنظمة الإنذار",
  alarm_system_desc:
    "تركيب جهاز انذار وصفارة انذار لوجود عدة مستويات من الحماية ضد السرقة.",
  steering_wheel_locks: "أقفال التوجيه",
  steering_wheel_locks_desc:
    "من الطرق الجيدة لحماية سيارتك من السرقة استخدام أقفال التوجيه",
  hidding_keys: "إخفاء المفاتيح",
  hidding_keys_desc: "لا تترك مفاتيحك على مرأى من الجميع لأنها قد تغري السارق.",
  parking_on_private_areas: "وقوف السيارات في أماكن خاصة",
  parking_on_private_areas_desc:
    "تفضيل أماكن وقوف السيارات على أرض خاصة ، يمكنك التأكد من أن سيارتك آمنة.",
  how_to_prevent_theft: "كيفية منع السرقة",
  secure_parking: "مواقف آمنة للسيارات",
  secure_parking_desc:
    "من أفضل الطرق لحماية سيارتك من السرقة هي ركنها في مكان مراقَب ومضاء ليلاً. ابحث عن أماكن وقوف السيارات التي تحتوي على كاميرات أو أنظمة إنذار أو موظفين معينين للمراقبة.",
  indoor_car_parking: "مواقف سيارات مغطاة",
  indoor_car_parking_desc:
    "احمِ سيارتك من الطقس والسرقة باستخدام المواقف المغطاة في وسط المدينة.",
  limited_availability: "توافر محدود",
  limited_availability_desc:
    "اختر ساحة انتظار ذات دخول وكلمات مرور مقيدة ، مما يجعل الأمر صعبًا على اللصوص.",
  megaparks: "ميجاباركس",
  megaparks_desc:
    "إذا كنت تستخدم موقف سيارات ضخم ، فتأكد من وقوف سيارتك بالقرب من المدخل وأنها مرئية بوضوح.",
  monitored_parking: "موقف سيارات مراقب",
  monitored_parking_desc:
    "ابحث عن أماكن وقوف السيارات التي يتم مراقبتها بواسطة الدوائر التلفزيونية المغلقة وقفل سيارتك دائمًا ، حتى لو تركتها لفترة قصيرة.",
  summary: "ملخص",
  summary_desc:
    "سرقة السيارات مشكلة خطيرة في جميع أنحاء العالم. تؤدي الزيادة في عدد المركبات على الطرق إلى زيادة نشاط المجرمين. غالبًا ما يتم بيع السيارات المسروقة لقطع غيار أو تصديرها إلى الخارج ، مما يجعل من الصعب على أصحابها استردادها. تتخذ الهيئات الشرطية والمؤسسات الحكومية العديد من الإجراءات لمنع ومكافحة هذا النوع من الجرائم ، بما في ذلك تحسين التكنولوجيا الأمنية والتعاون الدولي في محاكمة المجرمين. يعد التعليم والوعي بين السائقين مهمين أيضًا لمنع المواقف التي تفضل سرقة السيارات",
};
