<template>
  <div
    class="
      position-relative
      overflow-hidden
      p-3 p-md-5
      m-md-3
      text-center
      bg-grey
      rounded-3
      mb-4
    "
  >
    <div class="row d-flex flex-column align-items-center">
      <div class="col-12">
        <h3>{{ t("entered_vin_number") }}:</h3>
        <h4>{{ getData.vin_orginal }}</h4>
        <p>{{ t("may_be_incorrect") }}</p>
      </div>

      <div class="col-12 mb-4">
        <div class="row">
          <div
            class="
              col-sm-12 col-lg-8
              d-flex
              justify-content-center justify-content-md-end
              align-items-center
            "
          >
            <div class="row w-100">
              <div
                class="col-sm-12 col-lg-7 col-xl-8 p-0 text-center text-lg-end"
              >
                <h5 class="m-0 me-0 me-lg-2">{{ t("correct") }}:</h5>
              </div>

              <div
                class="
                  col-sm-12 col-lg-5 col-xl-4
                  p-0
                  text-center text-lg-start
                "
              >
                <h5 class="m-0" v-html="vinsValidator.vinCorrect"></h5>
              </div>
            </div>
          </div>
          <div
            class="
              col-sm-12 col-lg-4
              d-flex
              justify-content-center justify-content-lg-start
              mt-2 mt-lg-0
            "
          >
            <Button
              @check="setVinToSend(getData.vin_correct, false)"
              :customColor="true"
              classes="btn btn-success"
              >{{ t("check") }}</Button
            >
          </div>
        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="row">
          <div
            class="
              col-sm-12 col-lg-8
              d-flex
              justify-content-center justify-content-lg-end
              align-items-center
            "
          >
            <div class="row w-100">
              <div
                class="col-sm-12 col-lg-7 col-xl-8 p-0 text-center text-lg-end"
              >
                <h5 class="m-0 me-0 me-lg-2">{{ t("incorrect") }}:</h5>
              </div>

              <div
                class="
                  col-sm-12 col-lg-5 col-xl-4
                  p-0
                  text-center text-lg-start
                "
              >
                <h5 class="m-0" v-html="vinsValidator.vinOrginal"></h5>
              </div>
            </div>
          </div>

          <div
            class="
              col-sm-12 col-lg-4
              d-flex
              justify-content-center justify-content-lg-start
              mt-2 mt-lg-0
            "
          >
            <Button
              @check="sendIncorrect(getData.vin_orginal)"
              :customColor="true"
              classes="btn btn-error"
              >{{ t("check") }}</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import sendReq from "@/composables/sendReq";
import checkResponse from "@/composables/checkResponse";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Button from "./Button";
import { useRouter } from "vue-router";

export default {
  name: "VinValidator",
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    let vinToSend = ref("");
    // const vin = ref("");
    const vinsValidator = ref({
      vinOrginal: "",
      vinCorrect: "",
    });
    const router = useRouter();
    const { t, locale } = useI18n();

    onMounted(async () => {
      const checkedVin = vinNumber.value || null;

      if (checkedVin === null) {
        router.push({
          name: "checkingVin",
        });
      }
    });

    const vinNumber = computed(() => {
      return store.state.checkedVin;
    });

    const getData = computed(() => {
      return store.state.result;
    });

    const underscoreDiff = (vin, compare, color) => {
      let underscored = "";

      for (let i = 0; i < vin.length; i++) {
        if (vin[i] === compare[i]) {
          underscored += vin[i];
        } else {
          underscored += `<u class="${color}">${vin[i]}</u>`;
        }
      }

      return underscored;
    };

    vinsValidator.value.vinOrginal = underscoreDiff(
      getData.value.vin_orginal,
      getData.value.vin_correct,
      "text-danger"
    );
    vinsValidator.value.vinCorrect = underscoreDiff(
      getData.value.vin_correct,
      getData.value.vin_orginal,
      "text-success"
    );

    const { sendForm, response } = sendReq();

    const setVinToSend = async (vin, validator = true) => {
      router.push({
        name: "checkingVin",
        params: {
          vin: vin,
        },
      });
      store.dispatch("setCheckedVin", vin);
      vinToSend.value = vin;
      const send = await sendForm(vin, validator, 'noRobot');

      if (send == true) {
        const { directionRouter } = checkResponse(response.value);

        const direction = directionRouter();

        if (direction.params.vin) {
          router.push({
            name: direction.name,
            params: {
              vin: vinToSend.value,
            },
          });
        } else {
          router.push({
            name: direction.name,
          });
        }
      }
    };

    const sendIncorrect = (vin) => {
      router.push({
        name: "incorrectVin",
        params: {
          vin,
        },
      });
    };

    return {
      vinToSend,
      getData,
      setVinToSend,
      sendIncorrect,
      t,
      locale,
      vinsValidator,
    };
  },
};
</script>

<style scoped>
p {
  font-size: 18px;
}
.btn-error {
  background-color: #c81b17;
  color: white;
}
.btn-error:hover {
  background-color: #d01621;
  color: white;
}
</style>
