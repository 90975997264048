<template>
  <header>
    <div class="row">
      <div class="col-12">
        <div
          class="
            d-flex
            flex-column flex-md-row
            align-items-center
            pb-3
            mb-4
            border-bottom
          "
        >
          <!-- <a
            href="/"
            class="
              d-flex
              align-items-center
              text-dark text-decoration-none
              mx-auto
            "
          > -->
          <router-link
            class="
              d-flex
              align-items-center
              text-dark text-decoration-none
              mx-auto
            "
            to="/"
          >
            <img src="../assets/img/stolencars_logo.jpg" alt="StolenCars.eu" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="row mb-2 mb-md-0">
      <div class="col-12 text-center">
        <LanguageSelector />
      </div>
    </div>
  </header>
</template>

<script>
import LanguageSelector from "./LanguageSelector";

export default {
  name: "Header",
  components: {
    LanguageSelector,
  },
};
</script>